import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ChangeEventHandler, CSSProperties, FC } from "react"
import { cls } from "../../../../Shared/cls"
import style from "./ToggleSwitch.module.css"

export type Props = {
	onChange: ChangeEventHandler<HTMLInputElement> | undefined
	checked?: boolean
	text?: string
	textStyles?: CSSProperties
	labelClass?: string
	wrapperStyles?: CSSProperties
	showTextAbove?: boolean
}
export const ToggleSwitch: FC<Props> = ({
	onChange,
	checked,
	text,
	textStyles,
	labelClass,
	wrapperStyles,
	showTextAbove,
}) => {
	return (
		<label
			className={cls(style.switch, labelClass, { [style.showTextAbove]: showTextAbove === true })}
			style={wrapperStyles}>
			<input type={"checkbox"} checked={checked} onChange={onChange} />
			<span className={cls(style.slider, style.round)}>
				<FontAwesomeIcon className={style.iconColor} icon={faCheck} />
			</span>
			{text ? (
				<span className={style.text} style={textStyles}>
					{text}
				</span>
			) : null}
		</label>
	)
}
