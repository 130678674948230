import { faAngleDown, faAngleUp, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cloneDeep, filter, isArray } from "lodash"
import { FC, useEffect, useRef, useState } from "react"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { MeResponseAccountType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import {
	AddEditContactPersonModal,
	NewContactPersonRequest,
} from "../../CustomerPortal/AddEditContactPersonModal/AddEditContactPersonModal"
import {
	GetContactPerson,
	GetContactPersonDefinitionResponse,
} from "../../CustomerPortal/ConsumerContactPersons/ConsumerContactPersons"
import customerContactPersonsStyle from "../../CustomerPortal/ConsumerContactPersons/ConsumerContactPersons.module.css"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { CrossIcon, KontaktIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { RelativeCentered } from "../../RelativeCentered/RelativeCentered"
import { cls } from "../../Shared/cls"
import { AccentButton } from "../Components/Form/Buttons/Buttons"
import { formatPhoneNumberForSweden } from "../Components/Form/constants"
import { convertTextToInitials } from "../Helpers"
import { Project } from "../order-data-model"
import style from "./ProjectInputContactPersonSelector.module.css"

type Props = {
	isNew: boolean
	consumerId: string
	project: Partial<Project> | GetProject
	onSelected: (persons: GetContactPerson[]) => void
	onOpenAddNew: () => void
	onCloseAddNew: () => void
	onOrderChange?: (persons: GetContactPerson[]) => void
}
export const ProjectInputContactPersonSelector: FC<Props> = ({
	isNew,
	consumerId,
	project,
	onSelected,
	onOpenAddNew,
	onCloseAddNew,
	onOrderChange,
}) => {
	const auth = useAuth()
	const client = useClient()
	const permissions = usePermissions()

	const [selectedContactPersons, setSelectedContactPersons] = useState<GetContactPerson[]>([])
	const [showDropdown, setShowDropdown] = useState<boolean>(false)
	const [showSelector, setShowSelector] = useState<boolean>(true)
	const [contactPersons, setContactPersons] = useState<GetContactPerson[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [showAddNewContactPerson, setShowAddNewContactPerson] = useState(false)
	const [, setTick] = useState(0)
	const disableAutoAdd = useRef("id" in project && project.id.isProjectId)

	useEffect(() => {
		if (!auth.IsLoggedIn || !auth.Me) {
			return
		}

		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Read)) {
			return
		}

		let projectToUse = project
		setDataLoading(true)
		API.getWithRetries<GetContactPersonDefinitionResponse>(
			`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}`,
			true,
		)
			.then(async (res) => {
				const peeps = res.contactPersons
				if (
					!disableAutoAdd.current &&
					"contactName" in projectToUse &&
					auth.Me?.type === MeResponseAccountType.Consumer &&
					projectToUse.contactName &&
					projectToUse.contactPhone &&
					!peeps.find(
						(x) =>
							x.name.toLocaleLowerCase() ===
								(projectToUse as Partial<Project>).contactName?.toLocaleLowerCase() &&
							x.phone === (projectToUse as Partial<Project>).contactPhone,
					)
				) {
					const body: NewContactPersonRequest = {
						contactPerson: {
							name: projectToUse.contactName,
							phone: projectToUse.contactPhone,
						},
					}
					await API.postWithRetries<GetContactPerson>(
						`/customer-portal/contact-person-definitions-v1/${client.identifier}/${consumerId}`,
						body,
					)
						.then((newContactPerson) => {
							peeps.push(newContactPerson)
						})
						.catch(() => {})
				}
				if ("contactName" in projectToUse && projectToUse.contactName && projectToUse.contactPhone) {
					const contact = peeps.find(
						(x) =>
							x.name.toLocaleLowerCase() ===
								(projectToUse as Partial<Project>).contactName?.toLocaleLowerCase() &&
							x.phone === (projectToUse as Partial<Project>).contactPhone,
					)

					if (contact) {
						setSelectedContactPersons((x) => {
							return [contact]
						})
						setShowSelector(false)
					}
				}
				if (isArray(projectToUse.contactPersons) && projectToUse.contactPersons) {
					const items: GetContactPerson[] = projectToUse.contactPersons.reduce<GetContactPerson[]>(function (
						result,
						element,
					) {
						const person = peeps.find((x) => x.id === element.id)
						if (person) {
							result.push(person)
						}
						return result
					},
					[])

					if (items.length > 0) {
						setSelectedContactPersons(items)
						setShowSelector(false)
					}
				}
				setContactPersons(peeps)
				setDataLoading(false)
			})
			.catch(() => {
				setContactPersons([])
				setDataLoading(false)
			})
	}, [])

	function dropdownMenu() {
		if (dataLoading) {
			return (
				<div className={style.dropdown}>
					<AbsolutCentered>
						<Loader />
					</AbsolutCentered>
				</div>
			)
		}

		const filtered = filter(contactPersons, (x) => selectedContactPersons.find((y) => y.id === x.id) === undefined)

		const items = filtered.map((contactPerson) => {
			return (
				<div
					key={contactPerson.id}
					className={style.dropdownItem}
					onClick={() => {
						setSelectedContactPersons((val) => {
							const ret = [...val, contactPerson]
							onSelected(ret)
							return ret
						})

						setShowSelector(false)
						setShowDropdown(false)
					}}>
					{contactPerson.type === "UserAccount" ? (
						<div className={style.profilePic}>
							{contactPerson.profilePictureUrl ? (
								<img
									src={contactPerson.profilePictureUrl}
									onError={() => {
										contactPerson.profilePictureUrl = undefined
										setTick((tick) => tick + 1)
									}}
									alt="Profilbild"
								/>
							) : (
								<div
									className={customerContactPersonsStyle.noProfilePic}
									style={{ fontSize: "14px", color: "white" }}>
									{convertTextToInitials(contactPerson.name)}
								</div>
							)}
						</div>
					) : (
						<div style={{ width: "32px", display: "flex", justifyContent: "center" }}>
							<KontaktIcon size={22} className={style.contactIcon} />
						</div>
					)}
					<div className={customerContactPersonsStyle.userRowName}>
						<div style={{ fontWeight: 600 }}>{contactPerson.name}</div>
						<div style={{ wordBreak: "break-word", fontSize: "12px" }}>
							{contactPerson.type === "UserAccount" && contactPerson.email
								? contactPerson.email + ", "
								: null}
							{formatPhoneNumberForSweden(contactPerson.phone)}
						</div>
					</div>
				</div>
			)
		})

		return (
			<div className={style.dropdown}>
				{items}
				{permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Create) ? (
					<div
						className={style.dropdownItem}
						onClick={() => {
							setShowAddNewContactPerson(true)
							onOpenAddNew()
						}}>
						<div className={style.addContactPersonPfp}>+</div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div style={{ fontWeight: 600, fontSize: "14px" }}>Lägg till en ny kontaktperson</div>
						</div>
					</div>
				) : null}
			</div>
		)
	}

	function moveContactPerson(direction: "up" | "down", index: number) {
		if ((index === 0 && direction === "up") || (index === selectedContactPersons.length - 1 && direction === "down")) {
			return
		}

		setSelectedContactPersons((persons) => {
			const move1 = cloneDeep(persons[index])
			const move2Index = direction === "up" ? index - 1 : index + 1
			persons[index] = cloneDeep(persons[move2Index])
			persons[move2Index] = move1
			if (onOrderChange) {
				onOrderChange(persons)
			}
			return [...persons]
		})
	}

	function contactPersonRows() {
		let showEditAndRemoveParts: boolean

		if (isNew) {
			showEditAndRemoveParts = true
		} else {
			showEditAndRemoveParts = permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update)
		}

		return selectedContactPersons.map((selectedPerson, index) => {
			return (
				<div
					key={selectedPerson.id + "_selected" + index}
					className={style.selector}
					style={{ marginBottom: "10px" }}>
					{contactPersonRowUserImageElement(selectedPerson)}
					<div className={style.choosePersonText}>
						<div className={customerContactPersonsStyle.userRowName}>
							<div style={{ fontWeight: 600 }}>{selectedPerson.name}</div>
							<div style={{ wordBreak: "break-word", fontSize: "12px" }}>
								{formatPhoneNumberForSweden(selectedPerson.phone)}
							</div>
						</div>
						{showEditAndRemoveParts ? contactPersonRowEditAndRemoveParts(index, selectedPerson) : null}
					</div>
				</div>
			)
		})
	}

	function contactPersonRowEditAndRemoveParts(index: number, selectedPerson: GetContactPerson) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "15px",
				}}>
				{index > 0 ? (
					<FontAwesomeIcon
						icon={faArrowUp}
						className={style.arrowIcon}
						onClick={() => {
							moveContactPerson("up", index)
						}}
					/>
				) : null}
				{index < selectedContactPersons.length - 1 ? (
					<FontAwesomeIcon
						icon={faArrowDown}
						className={style.arrowIcon}
						onClick={() => {
							moveContactPerson("down", index)
						}}
					/>
				) : null}
				<CrossIcon
					size={24}
					className={style.closeXIcon}
					onClick={() => {
						setSelectedContactPersons((persons) => {
							persons.splice(
								persons.findIndex((x) => x.id === selectedPerson.id),
								1,
							)
							if (persons.length === 0) {
								setShowSelector(true)
							}
							onSelected(persons)
							return persons
						})
						setTick((tick) => tick + 1)
					}}
				/>
			</div>
		)
	}

	function contactPersonRowUserImageElement(selectedPerson: GetContactPerson) {
		const isUserAccount = selectedPerson.type === "UserAccount"
		const hasPfp = isUserAccount && selectedPerson.profilePictureUrl

		if (isUserAccount) {
			return (
				<div className={style.profilePic}>
					{hasPfp ? (
						<img
							src={selectedPerson.profilePictureUrl}
							onError={() => {
								selectedPerson.profilePictureUrl = undefined
								setTick((tick) => tick + 1)
							}}
							alt="Profilbild"
						/>
					) : (
						<div
							className={customerContactPersonsStyle.noProfilePic}
							style={{ fontSize: "14px", color: "white" }}>
							{convertTextToInitials(selectedPerson.name)}
						</div>
					)}
				</div>
			)
		}

		return (
			<div style={{ width: "36px", display: "flex", justifyContent: "center" }}>
				<KontaktIcon size={22} className={style.contactIcon} />
			</div>
		)
	}

	function contactPersonSelector() {
		if (!showSelector) {
			return null
		}

		return (
			<>
				<div
					className={cls(style.selector, style.notSelected)}
					onClick={() => {
						setShowDropdown(!showDropdown)
					}}>
					<KontaktIcon size={22} className={style.contactIcon} />
					<div className={style.choosePersonText}>
						<div>Välj kontaktperson</div>
						{showDropdown ? (
							<FontAwesomeIcon icon={faAngleUp} className={style.angleIcon} />
						) : (
							<FontAwesomeIcon icon={faAngleDown} className={style.angleIcon} />
						)}
					</div>
				</div>
				{showDropdown ? dropdownMenu() : null}
			</>
		)
	}

	function addMoreButton() {
		let showButton

		if (isNew) {
			showButton = !showSelector
		} else {
			showButton = !showSelector && permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Update)
		}

		if (!showButton) {
			return null
		}

		return (
			<div>
				<AccentButton
					className={style.moreContactPersonsBtn}
					onClick={() => {
						setShowSelector(true)
					}}>
					<span>Lägg till fler</span>
					<span style={{ fontSize: "24px" }}>+</span>
				</AccentButton>
			</div>
		)
	}

	if (dataLoading) {
		return (
			<RelativeCentered>
				<Loader />
			</RelativeCentered>
		)
	}

	return (
		<>
			{showAddNewContactPerson ? (
				<AddEditContactPersonModal
					consumerId={consumerId}
					contactPerson={undefined}
					onClose={() => {
						setShowAddNewContactPerson(false)
						setTimeout(() => {
							onCloseAddNew()
						}, 150)
					}}
					onDone={(newPerson) => {
						if (newPerson) {
							setContactPersons((persons) => {
								return [newPerson, ...persons]
							})
							setSelectedContactPersons((x) => {
								const ret = [...x, newPerson]
								onSelected(ret)
								return ret
							})
							setShowDropdown(false)
							setShowSelector(false)
						}
					}}
				/>
			) : null}
			<div className={style.wrapper}>
				{contactPersonRows()}
				{contactPersonSelector()}
				{addMoreButton()}
			</div>
		</>
	)
}
