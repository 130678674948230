import { faCircleUser } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleUp, faArrowLeft, faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { cloneDeep, debounce } from "lodash"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useClient } from "../../Client/ClientAndUserProvider"
import {
	ConsumerState,
	GetConsumer,
	GetConsumerType,
} from "../../CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { Paging, setPageAndFilterInUrl } from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import customerPortalOrdersStyle from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders.module.css"
import { CrossIcon, EmailIcon, FiltreraIcon, ListaOrdersIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { getLogger } from "../../Logging/getLogger"
import { API } from "../../network/API"
import { ToggleSwitch } from "../../Orders/Components/Form/ToggleSwitch/ToggleSwitch"
import { HorizontalScrollBox } from "../../Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import { addModalOpenClass, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { convertTextToInitials, parseNumberSafe } from "../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { RelativeCentered } from "../../RelativeCentered/RelativeCentered"
import { cls } from "../../Shared/cls"
import {
	rowsPerPageSection,
	selectedFilters,
	SkrappyTable,
	TableColumn,
	tablePagingSection,
} from "../../Shared/SkrappyTable/SkrappyTable"
import { consumerStateToHumanText } from "../ClientPortalConsumerManager/ClientPortalConsumerManager"
import { ClientPortalNewConsumerModal } from "../ClientPortalNewConsumerModal/ClientPortalNewConsumerModal"
import {
	ConsumerListFilterData,
	ConsumerListFilterDataSchema,
	ConsumerListFilterDataSchemaType,
	ConsumerListFilterSidebar,
	OrderByType,
	SortByDirection,
} from "../ConsumerListFilterSidebar/ConsumerListFilterSidebar"
import style from "./ClientPortalConsumerList.module.css"

type ClientPortalConsumerListProps = {
	dataLoading: boolean
	consumers: GetConsumer[]
	paging: Paging | null
	selectConsumer: (consumer: GetConsumer) => void
	setNewConsumer: (newConsumer: GetConsumer) => void
	hideFilterSection?: boolean
	hideCreateNew?: boolean
	headerText?: string
	hiddenTableColumns?: ClientPortalConsumerListTableColumn[]
	onExactSearchChange?: (value: boolean) => void
}

export enum ClientPortalConsumerListTableColumn {
	Customer = "Customer",
	CustomerNumber = "CustomerNumber",
	CustomerType = "CustomerType",
	Status = "Status",
	IsNew = "IsNew",
	OrderAmount = "OrderAmount",
	UserAmount = "UserAmount",
	InviteAmount = "InviteAmount",
}

const logger = getLogger("ClientPortalConsumerList")

export const ClientPortalConsumerList: FC<ClientPortalConsumerListProps> = ({
	dataLoading,
	consumers,
	paging,
	selectConsumer,
	setNewConsumer,
	hideFilterSection,
	hideCreateNew,
	headerText,
	hiddenTableColumns,
	onExactSearchChange,
}) => {
	const client = useClient()
	const permissions = usePermissions()

	const [, setTick] = useState(0)

	const [queryParams, setQueryParams] = useSearchParams({ page: "1", pageSize: "20", filters: JSON.stringify({}) })
	const [textSearchValue, setTextSearchValue] = useState<string>("")

	const [showFilterSidebar, setShowFilterSidebar] = useState(false)

	const [newConsumersAmount, setNewConsumersAmount] = useState<number | null>(null)
	const [totalConsumersAmount, setTotalConsumersAmount] = useState<number | null>(null)
	const [consumersWithOrdersAmount, setConsumersWithOrdersAmount] = useState<number | null>()
	const [consumersWithUsersAmount, setConsumersWithUsersAmount] = useState<number | null>()
	const [consumersWithInvitesAmount, setConsumersWithInvitesAmount] = useState<number | null>()

	const [useExactSearch, setUseExactSearch] = useState(false)

	const [filterBoxSelectionAlternative, setFilterBoxSelectionAlternative] = useState<"orders" | "users" | "invites">(
		"orders",
	)

	const searchInputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		API.get<number>(`/customer-portal/consumer-manager-v1/${client.identifier}/new-consumers-count`, true)
			.then((res) => {
				setNewConsumersAmount(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch new consumers amount in consumer manager with error ${err}`)
				setNewConsumersAmount(0)
			})

		API.get<number>(`/customer-portal/consumer-manager-v1/${client.identifier}/total-consumers-count`, true)
			.then((res) => {
				setTotalConsumersAmount(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch total consumers amount in consumer manager with error ${err}`)
				setTotalConsumersAmount(0)
			})

		API.get<number>(`/customer-portal/consumer-manager-v1/${client.identifier}/consumers-with-orders-count`, true)
			.then((res) => {
				setConsumersWithOrdersAmount(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch total consumers amount in consumer manager with error ${err}`)
				setConsumersWithOrdersAmount(0)
			})

		API.get<number>(`/customer-portal/consumer-manager-v1/${client.identifier}/consumers-with-users-count`, true)
			.then((res) => {
				setConsumersWithUsersAmount(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch total consumers amount in consumer manager with error ${err}`)
				setConsumersWithUsersAmount(0)
			})

		API.get<number>(`/customer-portal/consumer-manager-v1/${client.identifier}/consumers-with-invites-count`, true)
			.then((res) => {
				setConsumersWithInvitesAmount(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch total consumers amount in consumer manager with error ${err}`)
				setConsumersWithInvitesAmount(0)
			})
	}, [client.identifier])

	useEffect(() => {}, [client.identifier])

	useEffect(() => {
		searchInputRef.current?.focus()
	}, [consumers])

	useEffect(() => {
		let filters = getActiveFilters()

		if (filters.searchText) {
			setTextSearchValue(filters.searchText)
		} else if (textSearchValue) {
			setTextSearchValue("")
		}
	}, [queryParams])

	const filterRowsByTextDebounced = useCallback(
		debounce((text: string | null, data: ConsumerListFilterDataSchemaType) => {
			if (text !== null) {
				data.searchText = text
				delete data.sortBy
			} else {
				delete data.searchText
			}
			setPageAndFilterInUrl(1, null, data, setQueryParams)
		}, 1000),
		[queryParams],
	)

	const filterRowsByTextInstantCallback = useCallback(
		(text: string | null, data: ConsumerListFilterDataSchemaType) => {
			if (text !== null) {
				data.searchText = text
			} else {
				delete data.searchText
			}
			setPageAndFilterInUrl(1, null, data, setQueryParams)
		},
		[queryParams],
	)

	function getPageNumber() {
		return parseNumberSafe(queryParams.get("page"), 1)
	}

	function getPageSize() {
		return parseNumberSafe(queryParams.get("pageSize"), 20)
	}

	function getActiveFilters(): ConsumerListFilterDataSchemaType {
		const ret = ConsumerListFilterDataSchema.safeParse(JSON.parse(queryParams.get("filters") ?? "{}"))

		if (ret.success) {
			return ret.data
		} else {
			return {}
		}
	}

	const newConsumerHasBeenCreated = (newConsumer: GetConsumer) => {
		setNewConsumer(newConsumer)
	}

	function setStatsOrder(type: OrderByType) {
		const filters = getActiveFilters()
		let sortBy

		if (!filters.sortBy || filters.sortBy.type !== type) {
			sortBy = {
				type: type,
				direction: SortByDirection.DESC,
			}
		} else {
			if (filters.sortBy?.direction === SortByDirection.DESC) {
				sortBy = {
					type: type,
					direction: SortByDirection.ASC,
				}
			} else if (filters.sortBy?.direction === SortByDirection.ASC) {
				sortBy = undefined
			}
		}

		filters.sortBy = sortBy

		setPageAndFilterInUrl(getPageNumber(), getPageSize(), filters, setQueryParams)
	}

	function columnSortingArrows(type: OrderByType) {
		const filters = getActiveFilters()

		if (filters.searchText) {
			return null
		}

		return (
			<span style={{ display: "flex", flexDirection: "column" }}>
				<FontAwesomeIcon
					icon={faAngleUp}
					style={{
						color:
							!filters.sortBy ||
							filters.sortBy?.type !== type ||
							filters.sortBy?.direction === SortByDirection.ASC
								? undefined
								: "transparent",
					}}
				/>
				<FontAwesomeIcon
					icon={faAngleDown}
					style={{
						color:
							!filters.sortBy ||
							filters.sortBy?.type !== type ||
							filters.sortBy?.direction === SortByDirection.DESC
								? undefined
								: "transparent",
					}}
				/>
			</span>
		)
	}

	function tableColumns(): TableColumn<GetConsumer>[] {
		return [
			{
				headerContent: "Kund",
				headerClass: style.consumerColumn,
				cellContent: {
					content: (data) => (
						<div style={{ display: "flex", gap: "10px" }}>
							<div className={style.consumerListItemPic}>
								{data.profilePictureUrl ? (
									<img
										src={data.profilePictureUrl}
										onError={() => {
											data.profilePictureUrl = undefined
											setTick((tick) => tick + 1)
										}}
										alt="Profilbild"
									/>
								) : (
									<div className={style.noProfilePic}>
										{convertTextToInitials(data.consumerDetails.name)}
									</div>
								)}
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<strong>{data.consumerDetails.name}</strong>
								<span>
									{exhaustive(data.consumerDetails, "type", {
										CompanyConsumerDetails: (it) => {
											return it.orgNumber
										},
										PrivateConsumerDetails: (it) => {
											return it.personNumber
										},
									})}
								</span>
							</div>
						</div>
					),
					className: () => style.consumerCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.Customer) !== true,
			},
			{
				headerContent: "Kundnr",
				cellContent: {
					content: (data) => <strong>{data.customerNumber}</strong>,
					className: () => style.customerNumberCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.CustomerNumber) !== true,
			},
			{
				headerContent: "Kundtyp",
				cellContent: {
					content: (data) => (
						<div className={style.customerType}>
							{exhaustive(data.consumerDetails, "type", {
								CompanyConsumerDetails: () => {
									return "Företag"
								},
								PrivateConsumerDetails: () => {
									return "Privatperson"
								},
							})}
						</div>
					),
					className: () => style.consumerTypeCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.CustomerType) !== true,
			},
			{
				headerContent: "Status\xa0",
				cellContent: {
					content: (data) => <strong>{consumerStateToHumanText(data.state)}</strong>,
					className: () => style.consumerStateCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.Status) !== true,
			},
			{
				headerContent: "Ny kund?\xa0",
				cellContent: {
					content: (data) => <strong>{data.isNew ? "Ny" : ""}</strong>,
					className: () => style.consumerStateCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.IsNew) !== true,
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Orders)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "5px" }}>
						Ordrar
						{columnSortingArrows(OrderByType.Orders)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<ListaOrdersIcon
								className={cls(style.tableIcon, {
									[style.emptyNumberCircleIcon]: !data.stats || data.stats?.orderAmount === 0,
								})}
								size={24}
							/>
							<span
								className={cls(style.numberCircle, {
									[style.emptyNumberCircle]: !data.stats || data.stats?.orderAmount === 0,
								})}>
								{data.stats ? data.stats.orderAmount : 0}
							</span>
						</div>
					),
					className: () => style.orderAmountCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.OrderAmount) !== true,
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Users)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "5px" }}>
						Användare
						{columnSortingArrows(OrderByType.Users)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<FontAwesomeIcon
								className={cls(style.tableIcon, {
									[style.emptyNumberCircleIcon]: !data.stats || data.stats?.userAmount === 0,
								})}
								icon={faCircleUser}
							/>
							<span
								className={cls(style.numberCircle, {
									[style.emptyNumberCircle]: !data.stats || data.stats?.userAmount === 0,
								})}>
								{data.stats ? data.stats.userAmount : 0}
							</span>
						</div>
					),
					className: () => style.userAmountCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.UserAmount) !== true,
			},
			{
				headerOnClick: () => {
					setStatsOrder(OrderByType.Invites)
				},
				headerClass: cls(style.textWithIconColumn, style.clickableColumn),
				headerContent: (
					<span style={{ display: "flex", alignItems: "center", gap: "15px", paddingRight: "10px" }}>
						Inbjudningar
						{columnSortingArrows(OrderByType.Invites)}
					</span>
				),
				cellContent: {
					content: (data) => (
						<div className={style.tableIconAndTextWrapper}>
							<EmailIcon
								className={cls(style.tableIcon, {
									[style.emptyNumberCircleIcon]: !data.stats || data.stats?.inviteAmount === 0,
								})}
								size={24}
							/>
							<span
								className={cls(style.numberCircle, {
									[style.emptyNumberCircle]: !data.stats || data.stats?.inviteAmount === 0,
								})}>
								{data.stats ? data.stats.inviteAmount : 0}
							</span>
						</div>
					),
					className: () => style.inviteAmountCell,
				},
				condition: () => hiddenTableColumns?.includes(ClientPortalConsumerListTableColumn.InviteAmount) !== true,
			},
		]
	}

	function getFilterDataValue(
		key: keyof ConsumerListFilterDataSchemaType,
		value: string | string[] | number | boolean | object,
	): string | number {
		return exhaustive(key, {
			name: () => (value as string[]).join(", "),
			customerNumber: () => "Kundnr: " + (value as string[]).join(", "),
			consumerType: () => (value === GetConsumerType.PrivatePerson ? "Privatpersoner" : "Företag"),
			state: () =>
				(value as string[])
					.map((x) => {
						return consumerStateToHumanText(x as ConsumerState)
					})
					.join(", "),
			isNew: () => `Ny kund: ${value === "true" ? "Ja" : "Nej"}`,
			hasUsers: () => `Har användare: ${value === "true" ? "Ja" : "Nej"}`,
			hasOrders: () => `Har ordrar: ${value === "true" ? "Ja" : "Nej"}`,
			hasInvites: () => `Har inbjudningar: ${value === "true" ? "Ja" : "Nej"}`,
			searchText: () => "",
			sortBy: () => "",
		})
	}

	function clearFilters() {
		setQueryParams((queryParams) => {
			queryParams.delete("filters")
			queryParams.set("page", "1")

			return queryParams
		})
	}

	function filterRowsByText(text: string) {
		if (!text) {
			setTextSearchValue("")
		} else {
			setTextSearchValue(text)
		}
		filterRowsByTextDebounced(text || null, getActiveFilters())
	}

	function filterRowsByTextInstant(text: string) {
		if (!text) {
			setTextSearchValue("")
		} else {
			setTextSearchValue(text)
		}
		filterRowsByTextInstantCallback(text || null, getActiveFilters())
	}

	function filterSection() {
		if (hideFilterSection) {
			return null
		}

		return (
			<div className={style.filterSection}>
				<div className={style.firstRow}>
					{permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Create) ? (
						<span className={customerPortalOrdersStyle.onlyDesktop}>
							<ClientPortalNewConsumerModal
								newConsumerButtonClass={style.newConsumerButton}
								onAdd={newConsumerHasBeenCreated}
							/>
						</span>
					) : null}
					<div className={cls(style.searchInputWrapper, { [style.disabled]: dataLoading })}>
						<span className={style.searchIcon}>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</span>
						<input
							disabled={dataLoading}
							ref={searchInputRef}
							className={style.searchInput}
							onChange={(e) => filterRowsByText(e.target.value.toLocaleLowerCase())}
							placeholder={"Sök"}
							value={textSearchValue}
						/>
						<button
							onClick={() => filterRowsByTextInstant("")}
							className={style.clearSearchButton}
							disabled={dataLoading}>
							{textSearchValue !== "" ? (
								<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
							) : (
								<span style={{ marginLeft: "1rem" }} />
							)}
						</button>
					</div>
					<ToggleSwitch
						wrapperStyles={{ margin: "auto 0" }}
						checked={useExactSearch}
						showTextAbove={true}
						text={"Exakt sök?"}
						onChange={(x) => {
							setUseExactSearch(x.target.checked)
							if (onExactSearchChange) {
								onExactSearchChange(x.target.checked)
							}
						}}
					/>
					<div className={cls({ [style.disabled]: dataLoading })}>
						<button
							disabled={dataLoading}
							className={cls(
								style.filterButton,
								customerPortalOrdersStyle.filterButton,
								customerPortalOrdersStyle.onlyDesktop,
							)}
							onClick={() => {
								if (dataLoading) {
									return
								}
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<span>Filtrera</span>
							<FiltreraIcon size={22} />
						</button>
						<button
							disabled={dataLoading}
							className={cls(
								style.filterButton,
								customerPortalOrdersStyle.filterButton,
								customerPortalOrdersStyle.onlyMobile,
							)}
							onClick={() => {
								if (dataLoading) {
									return
								}
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<FiltreraIcon size={22} />
						</button>
					</div>
				</div>
				<div className={style.secondRow}>
					{selectedFilters(
						getActiveFilters(),
						(key, value) => getFilterDataValue(key as keyof ConsumerListFilterDataSchemaType, value),
						(key) => {
							const activeFilters: { [key: string]: any } = cloneDeep(getActiveFilters())
							delete activeFilters[key]
							setPageAndFilterInUrl(1, null, activeFilters, setQueryParams)
						},
						() => {
							clearFilters()
						},
					)}
				</div>
			</div>
		)
	}

	function filterBoxSelectionAlternativeToHumanText(text: typeof filterBoxSelectionAlternative) {
		return exhaustive(text, {
			invites: () => "Inbjudningar",
			orders: () => "Ordrar",
			users: () => "Användare",
		})
	}

	function summarySection() {
		return (
			<div className={style.summarySectionWrapper}>
				<HorizontalScrollBox
					cellClassName={style.summarySectionBoxHorizontalScroll}
					key={"consumerSummaryBoxOptions"}>
					<div className={cls(style.summarySectionBox, style.summarySectionClickableBox)}>
						<div className={style.summarySectionBoxTitleAndIconWrapper}>
							<div className={style.summarySectionBoxTitleText}>Nya kunder</div>
							<div
								className={style.summarySectionBoxTitleIcon}
								onClick={() => {
									if (getActiveFilters()?.isNew !== "true") {
										const data: ConsumerListFilterData = {
											isNew: "true",
										}
										setPageAndFilterInUrl(null, null, data, setQueryParams)
									}
								}}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</div>
						</div>
						<div className={style.summarySectionBoxContent}>
							{newConsumersAmount === null ? (
								<FontAwesomeIcon icon={faSpinner} spin={true} />
							) : (
								newConsumersAmount
							)}
						</div>
						<div style={{ height: "25px" }}></div>
					</div>

					<div className={cls(style.summarySectionBox, style.summarySectionClickableBox)}>
						<div className={style.summarySectionBoxTitleAndIconWrapper}>
							<div className={style.summarySectionBoxTitleText}>
								Kunder med{" "}
								{filterBoxSelectionAlternativeToHumanText(
									filterBoxSelectionAlternative,
								).toLocaleLowerCase()}
							</div>
							<div
								className={style.summarySectionBoxTitleIcon}
								onClick={() => {
									exhaustive(filterBoxSelectionAlternative, {
										invites: () => {
											if (getActiveFilters()?.hasInvites !== "true") {
												const data: ConsumerListFilterData = {
													hasInvites: "true",
												}
												setPageAndFilterInUrl(null, null, data, setQueryParams)
											}
										},
										orders: () => {
											if (getActiveFilters()?.hasOrders !== "true") {
												const data: ConsumerListFilterData = {
													hasOrders: "true",
												}
												setPageAndFilterInUrl(null, null, data, setQueryParams)
											}
										},
										users: () => {
											if (getActiveFilters()?.hasUsers !== "true") {
												const data: ConsumerListFilterData = {
													hasUsers: "true",
												}
												setPageAndFilterInUrl(null, null, data, setQueryParams)
											}
										},
									})
								}}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</div>
						</div>
						<div className={style.summarySectionBoxContent}>
							{newConsumersAmount === null ? (
								<FontAwesomeIcon icon={faSpinner} spin={true} />
							) : (
								exhaustive(filterBoxSelectionAlternative, {
									invites: () => consumersWithInvitesAmount,
									orders: () => consumersWithOrdersAmount,
									users: () => consumersWithUsersAmount,
								})
							)}
						</div>

						<div className={style.summarySectionBoxOptions}>
							<div
								className={cls(style.summarySectionBoxOption, {
									[style.summarySectionBoxOptionSelected]: filterBoxSelectionAlternative === "orders",
								})}
								onClick={() => {
									setFilterBoxSelectionAlternative("orders")
								}}>
								{filterBoxSelectionAlternativeToHumanText("orders")}
							</div>
							<div
								className={cls(style.summarySectionBoxOption, {
									[style.summarySectionBoxOptionSelected]: filterBoxSelectionAlternative === "users",
								})}
								onClick={() => {
									setFilterBoxSelectionAlternative("users")
								}}>
								{filterBoxSelectionAlternativeToHumanText("users")}
							</div>
							<div
								className={cls(style.summarySectionBoxOption, {
									[style.summarySectionBoxOptionSelected]: filterBoxSelectionAlternative === "invites",
								})}
								onClick={() => {
									setFilterBoxSelectionAlternative("invites")
								}}>
								{filterBoxSelectionAlternativeToHumanText("invites")}
							</div>
						</div>
					</div>

					<div className={style.summarySectionBox}>
						<div className={style.summarySectionBoxTitleAndIconWrapper}>
							<div className={style.summarySectionBoxTitleText}>Totalt antal kunder</div>
						</div>
						<div className={style.summarySectionBoxContent}>
							{totalConsumersAmount === null ? (
								<FontAwesomeIcon icon={faSpinner} spin={true} />
							) : (
								totalConsumersAmount
							)}
						</div>
						<div style={{ height: "25px" }}></div>
					</div>
				</HorizontalScrollBox>
			</div>
		)
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Read)) {
		return null
	}

	return (
		<>
			{showFilterSidebar && consumers ? (
				<ConsumerListFilterSidebar
					consumers={consumers}
					onClose={() => {
						setShowFilterSidebar(false)
						removeModalOpenClass()
					}}
					onDone={(data) => {
						setPageAndFilterInUrl(1, null, data, setQueryParams)
					}}
					filterData={getActiveFilters()}
				/>
			) : null}
			<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<div className={style.headerAndNewConsumerSection}>
					<div className={style.headerText}>{headerText || "Kunder"}</div>
					{permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Create) && !hideCreateNew ? (
						<span className={customerPortalOrdersStyle.onlyMobile}>
							<ClientPortalNewConsumerModal
								newConsumerButtonClass={style.newConsumerButton}
								onAdd={newConsumerHasBeenCreated}
							/>
						</span>
					) : null}
					{summarySection()}
				</div>
				{filterSection()}
				{rowsPerPageSection(
					getActiveFilters(),
					getPageSize(),
					(page) => {
						setPageAndFilterInUrl(1, page, getActiveFilters(), setQueryParams)
					},
					dataLoading,
				)}
				{dataLoading ? (
					<RelativeCentered styles={{ height: "300px" }}>
						<Loader />
					</RelativeCentered>
				) : (
					<SkrappyTable
						data={consumers}
						columns={tableColumns()}
						tableClass={style.consumersTable}
						tableRowClass={style.clickableRow}
						onRowClick={(consumer) => {
							selectConsumer(consumer)
						}}
					/>
				)}
				{tablePagingSection(
					paging,
					{ pageNumber: getPageNumber(), pageSize: getPageSize() },
					() => {
						setPageAndFilterInUrl(getPageNumber() - 1, null, getActiveFilters(), setQueryParams)
					},
					() => {
						setPageAndFilterInUrl(getPageNumber() + 1, null, getActiveFilters(), setQueryParams)
					},
					(number) => {
						setPageAndFilterInUrl(number, null, getActiveFilters(), setQueryParams)
					},
					dataLoading,
				)}
			</div>
		</>
	)
}
