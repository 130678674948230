import { faClock } from "@fortawesome/free-regular-svg-icons"
import {
	faAngleDown,
	faAngleRight,
	faArrowRotateRight,
	faArrowsLeftRight,
	faSpinner,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useJsApiLoader } from "@react-google-maps/api"
import ObjectID from "bson-objectid"
import { ConsumerSelfRegistrationMode, DiscountCodeMode } from "Client/FeatureTypes"
import { DynamicAgreementTypes } from "Client/GetClientResponse"
import { exhaustive } from "exhaustive"
import { cloneDeep, isArray, isEmpty, isEqual, isNull, isPlainObject, isString, keys, map, some, split, uniq } from "lodash"
import {
	KlarnaSession,
	SetupCheckoutSessionRequest,
	SetupCheckoutSessionResponse,
} from "Orders/api/CheckoutSessionApiModel"
import {
	AgreementOptions,
	Consumer,
	DynamicOrderAgreement,
	DynamicOrderAgreementTypes,
	NewOrder,
	NewOrderContact,
	NewOrderDate,
	NewOrderProduct,
	NewOrderRequest,
	NewOrderResponse,
	OrderLicenseAgreements,
	PaymentMethod,
} from "Orders/api/NewOrderApi"
import { CategoryCard } from "Orders/OrderContainer/SubComponents/CategoryImage/CategoryCard"
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { lets } from "Shared/lets"
import { metric } from "Shared/metric"
import { useZodClientLocalStorage } from "Shared/useClientLocalStorage"
import { when } from "Shared/when"
import { v4 } from "uuid"
import { z } from "zod"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { ClientInstance, ProductCategoryInstance } from "../../../Client/ClientInstance"
import { ConsumerProjectMode, useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { ConsumerContextType, useConsumer } from "../../../Client/ConsumerContext"
import { ProductServiceUnit, ProductTransportationSelectionType } from "../../../Client/ProductDefinitionsByCategories"
import { GetProject } from "../../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { ArrowLeftIcon, ArrowRightIcon, DatumIcon, KontaktIcon, MarkingIcon, PenIcon, TrashIcon } from "../../../Icons/Icon"
import { Loader } from "../../../Loader/Loader"
import { getLogger } from "../../../Logging/getLogger"
import { useNavigator } from "../../../Navigator/useNavigator"
import { API, ServerError } from "../../../network/API"
import { ProductImageType } from "../../../ProductDefinitions"
import { cls } from "../../../Shared/cls"
import { LocalDate, LocalTime } from "../../../Shared/commonTypes"
import { ConfirmModal, ConfirmModalCloseReason } from "../../../Shared/ConfirmModal/ConfirmModal"
import { EventQueue } from "../../../Shared/eventQueue"
import { getKey } from "../../../Shared/getKey"
import {
	addAddressAndExactDeliveryMarkersToMap,
	GoogleMapComponent,
	maybeSetMapBoundsForExactDelivery,
} from "../../../Shared/GoogleMapComponent/GoogleMapComponent"
import { indexWithinBounds } from "../../../Shared/indexWithinBounds"
import { notNull } from "../../../Shared/notNull"
import { throwIllegalState } from "../../../Shared/throwIllegalState"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"
import { getDatesFromDateSlotAsString } from "../../DateSelectModule/DateSelectModule"
import { cleanMimeString, getImageURLFromBase64 } from "../../Helpers"
import { OrderItem, OrderItemDateType, OrderItemProduct, Project } from "../../order-data-model"
import { getDateAsHugeFormat } from "../../OrderCompleted/OrderCompletedSummary/OrderCompletedSummary"
import { useBasket } from "../../OrderContainer/BasketProvider"
import {
	getAmountOfArticlesInOrderItemProduct,
	getAmountOfProductsInOrderItemProduct,
	productServiceUnitToHumanText,
} from "../../OrderContainer/Logic"
import { OpenModalTypes, removeModalOpen } from "../../OrderContainer/OrderContainer"
import { modalOpenOrderItemIndexKey, modalOpenQueryKey } from "../../OrderContainer/OrderContainerWrapper"
import { contactInformationOneLineText, orderItemZoneElement } from "../../OrderContainer/SubComponents/BasketSection"
import { googleMiniMapOptions, libraries, ProjectInputModule } from "../../ProjectInputModule/ProjectInputModule"
import { unitFormatter } from "../../unit-formatter"
import { FinalizeButton } from "../Form/Buttons/Buttons"
import { MultiStateSwitch } from "../Form/MultiStateSwitch/MultiStateSwitch"
import { removeModalOpenClass } from "../ModulePopup/ModulePopup"
import {
	IndividualInfoType,
	OrderConfirmCustomerInformation,
	OrganizationInfoType,
} from "../OrderConfirmCustomerInformation/OrderConfirmCustomerInformation"
import { OrderConfirmPriceSummarySection } from "../OrderConfirmPriceSummarySection/OrderConfirmPriceSummarySection"
import { ProductImage } from "../ProductImage/ProductImage"
import { Mbact } from "../Text/Mbact/Mbact"
import { Mbt } from "../Text/Mbt/Mbt"
import { MbtH5 } from "../Text/MbtH5/MbtH5"
import { SbtH2 } from "../Text/SbtH2/SbtH2"
import { SbtH4 } from "../Text/SbtH4/SbtH4"
import { SbtInvalid } from "../Text/SbtInvalid/SbtInvalid"
import { getDataForTransportSvg, TransportDescription } from "../TransportDescription/TransportDescription"
import { DiscountCodeVerifier } from "./DiscountCodeVerifier"
import { AgreementLicenseToggle, LicenseToggle } from "./LicenseToggle"
import style from "./OrderConfirmCheckout.module.css"

const logger = getLogger("OrderConfirmCheckout")

const skrappyTermsAndConditionsUrl = "https://skrappy.se/allmanna-villkor-konsument/"

export function getStreetFromAddressComponents(components?: google.maps.GeocoderAddressComponent[]): string {
	if (!components) return ""

	const street =
		components.find((x) => x.types.includes("route"))?.long_name ||
		// plus_code is googles "universal" address based on latitude and longitude. See https://maps.google.com/pluscodes/ for more info
		// it's used before locality, because sometimes locality is a city and sometimes it's not. Most of the time when
		// locality is a city, we'll get route and/or plus_code. As such if we don't resolve locality last
		// we may get the city twice. Addresses are fun ლ(¯ロ¯"ლ)
		components.find((x) => x.types.includes("plus_code"))?.long_name ||
		components.find((x) => x.types.includes("locality"))?.long_name
	const streetNumber =
		components.find((x) => x.types.includes("street_number"))?.long_name ||
		components.find((x) => x.types.includes("premise"))?.long_name

	return ((street || "") + " " + (streetNumber || "")).trim()
}

export function getCityFromAddressComponents(components?: google.maps.GeocoderAddressComponent[]): string {
	if (!components) return ""

	return (
		components.find((x) => x.types.includes("postal_town"))?.long_name ||
		components.find((x) => x.types.includes("locality"))?.long_name ||
		""
	)
}

export function getZipCodeFromAddressComponents(components?: google.maps.GeocoderAddressComponent[]): string {
	if (!components) return ""

	return (components.find((x) => x.types.includes("postal_code"))?.long_name || "").trim()
}

type KlarnaAuthCallbackData = {
	authorization_token: string
	show_form: string
	approved: boolean
	finalize: boolean
	error: any
}

function createNewOrderProductsFromOrderItemProducts(
	orderItemProducts: OrderItemProduct[],
	client: ClientInstance,
): NewOrderProduct[] {
	return orderItemProducts.map((orderItemProduct) => {
		if (!orderItemProduct.amount && !orderItemProduct?.wasteType?.wasteTypeId && !orderItemProduct?.packagingMethod) {
			throw new Error(
				`Amount and waste type id not set for order item product id: "${orderItemProduct.productId}" and name: "${orderItemProduct.name}"`,
			)
		}

		const amount = getAmountOfArticlesInOrderItemProduct(orderItemProduct, client)
		if (amount === undefined) throw Error("Amount is undefined!")

		const packagingMethod = orderItemProduct.packagingMethod
			? client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]
			: null

		const newOrderProduct: NewOrderProduct = {
			productDefinitionId: orderItemProduct.productId,
			amount: amount,
			serviceId: orderItemProduct.serviceId,
			wasteTypeId: orderItemProduct?.wasteType?.wasteTypeId,
			expectedArticles: orderItemProduct.articles?.toExpectedArticles() || [],
			packagingMethod:
				packagingMethod && orderItemProduct?.packagingMethod?.amount !== undefined
					? {
							name: packagingMethod.name,
							multiplier: packagingMethod.multiplier,
							quantity: orderItemProduct.packagingMethod.amount,
							id: packagingMethod.id,
							transportId: packagingMethod.transportationId,
							unit: packagingMethod.productUnit,
					  }
					: undefined,
		}
		return newOrderProduct
	})
}

const CheckoutSessionIdAndVersionSchema = z.object({
	id: z.string(),
	version: z.number(),
})
type CheckoutSessionIdAndVersion = z.input<typeof CheckoutSessionIdAndVersionSchema>

const setupCheckoutSession = async (
	client: ClientInstance,
	idAndVersion: CheckoutSessionIdAndVersion,
	orderItems: OrderItem[],
	customerInformation: IndividualInfoType | OrganizationInfoType,
	consumer: ConsumerContextType,
	discountCodeIdentifier: string | null,
): Promise<SetupCheckoutSessionResponse | "VersionConflict" | null> => {
	const personNumber = "personNumber" in customerInformation ? customerInformation.personNumber : null
	const session: SetupCheckoutSessionRequest = {
		checkoutSession: {
			id: idAndVersion.id,
			version: idAndVersion.version,
			orders: orderItems.map((orderItem) => {
				return {
					transportZoneId: orderItem.zoneId,
					timeSlotId: orderItem.time?.timeslotId,
					dateSlotId: orderItem.date && !isString(orderItem.date) ? orderItem.date.dateSlotId : undefined,
					expectedOrderArticles: orderItem.articles?.toExpectedArticles() || [],
					products: createNewOrderProductsFromOrderItemProducts(orderItem.products as OrderItemProduct[], client),
					date: orderItem.date && isString(orderItem.date) ? (orderItem.date as LocalDate) : undefined,
					timeExact: orderItem.time?.specificTime ? (orderItem.time.timeValue as LocalTime) : null,
					address: {
						street: orderItem.project.street,
						zipcode: orderItem.project.zipcode || null,
						city: orderItem.project.city,
						country: "Sweden",
					},
					transportId: orderItem.transportId,
				}
			}),
			customer: {
				email: customerInformation?.email,
				personNumber: personNumber,
			},
			consumer: {
				consumerId: consumer?.consumerId ?? null,
			},
			discountCodeIdentifier: discountCodeIdentifier,
		},
	}

	return API.postWithRetries<SetupCheckoutSessionResponse | null>(
		`/order-ui/checkout-session-v1/${client.identifier}`,
		session,
	)
		.then((response) => {
			if (!response || isEmpty(response)) {
				return null
			}

			logger.info("Got CheckoutSession: ", response)

			return response
		})
		.catch((error: ServerError<any>) => {
			if (error.status === 409) {
				logger.warn("Version Conflict", error)
				return "VersionConflict"
			}
			// Don't throw an error, just log it in console and return null
			// No point in showing error screen simply because the session setup failed, that has no value for me as a consumer
			logger.error("SetupCheckoutSessionRequest failed, continuing without Checkout Session.", error)
			return null
		})
}

export type SelectedFile = {
	id: string
	imageName: string
	url: string
	file?: File
	uploading: boolean
	uploaded: boolean
	uploadFailed: boolean
	tooBig: boolean
	originalName: string
	type: "Image" | "Generic"
}

function newCheckoutSessionIdAndVersion() {
	return { id: ObjectID().toHexString(), version: 0 }
}

export const maxImageSize = 10000000

export function orderItemDateElementContent(client: ClientInstance, date: OrderItemDateType) {
	if (!date) {
		return null
	}

	if (isString(date)) {
		return <span>{getDateAsHugeFormat(date)}</span>
	} else {
		const dateSlot = client.possibleDateSlots[date.dateSlotId]

		if (!dateSlot) {
			return null
		}

		const dates = getDatesFromDateSlotAsString(dateSlot)
		return (
			<span>
				<span>{dateSlot.name}</span>
				<div style={{ fontSize: "12px", display: "flex", gap: "2px" }}>
					{date.specificDate ? (
						date.specificDate
					) : (
						<>
							<span>{dates.from}</span>
							{dates.to > dates.from ? (
								<>
									<FontAwesomeIcon icon={faArrowsLeftRight} />
									<span>{dates.to}</span>
								</>
							) : null}
						</>
					)}
				</div>
			</span>
		)
	}
}

enum OrderConfirmErrorState {
	DiscountError = "DiscountError",
	RegularError = "RegularError",
	ClientSideKeyCollision = "ClientSideKeyCollision",
}

type OrderConfirmErrorDiscount = {
	type: OrderConfirmErrorState.DiscountError
}

type OrderConfirmErrorRegular = {
	type: OrderConfirmErrorState.RegularError
}

type OrderConfirmErrorClientSideKeyCollision = {
	type: OrderConfirmErrorState.ClientSideKeyCollision
	id: string
}

type OrderConfirmError = OrderConfirmErrorDiscount | OrderConfirmErrorRegular | OrderConfirmErrorClientSideKeyCollision

export const OrderConfirmCheckout: FC = () => {
	removeModalOpenClass()
	const auth = useAuth()
	const basket = useBasket()
	const client = useClient()
	const consumer = useConsumer()
	const consumerCatalog = useConsumerCatalog()
	const navigator = useNavigator()

	const [queryParams, setQueryParams] = useSearchParams()
	const previousQueryParams = useRef<URLSearchParams>(queryParams)

	const [, setTick] = useState<number>(0)

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [errorSubmitting, setErrorSubmitting] = useState<OrderConfirmError | null>(null)
	const [commentForTransporter, setCommentForTransporter] = useState<Map<string, string>>(new Map())

	const [showConfirmChangeTransportationModal, setShowConfirmChangeTransportationModal] = useState<{
		orderItemId: string
		oldValue: string
		newValue: string
	} | null>(null)

	const [acceptSkrappyTerms, setAcceptSkrappyTerms] = useState<boolean>(false)
	const [acceptCustomerTerms, setAcceptCustomerTerms] = useState<boolean>(false)
	const [dynamicAgreementsState, setDynamicAgreementsState] = useState<{ [title: string]: boolean | undefined }>({})
	const [customerInformation, setCustomerInformation] = useState<IndividualInfoType | OrganizationInfoType | null>(null)
	const lastCustomerInformation = useRef<IndividualInfoType | OrganizationInfoType | null>(null)
	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [checkoutSessionLoading, setCheckoutSessionLoading] = useState<boolean>(true)

	const [checkoutSessionIdAndVersion, setCheckoutSessionIdAndVersion, removeCheckoutSessionIdAndVersion] =
		useZodClientLocalStorage<CheckoutSessionIdAndVersion>(
			"checkout-session-id-version",
			() => newCheckoutSessionIdAndVersion(),
			CheckoutSessionIdAndVersionSchema,
		)
	const lastRequestedIdAndVersionRef = useRef<CheckoutSessionIdAndVersion | null>(null)
	const lastOrderItems = useRef<OrderItem[] | null>(null)

	const [klarnaSession, setKlarnaSession] = useState<KlarnaSession | null>(null)
	const [klarnaFailed, setKlarnaFailed] = useState<boolean>(false)
	const [usePayWithKlarna, setUsePayWithKlarna] = useState<boolean>(false)

	const [addExactDelivery, setAddExactDelivery] = useState<OrderItem | null>(null)

	const orderItemToMapReference = useRef<{ [orderItemId: string]: google.maps.Map }>({})
	const orderItemToMarkerReference = useRef<{ [orderItemId: string]: google.maps.marker.AdvancedMarkerElement[] }>({})

	const selectedImages = useRef<{ [orderItemId: string]: { [imageId: string]: SelectedFile } }>({})

	const readOnlyMode = useMemo(() => {
		let ret = false
		if (
			client.features.consumerSelfRegistrationSettings.mode !== ConsumerSelfRegistrationMode.Deny &&
			!auth.IsLoggedIn
		) {
			ret = true
		}
		return ret
	}, [client, auth])

	const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const onConfirmRefresh = useCallback(function (event: BeforeUnloadEvent) {
		if (some(selectedImages.current, (x) => keys(x).length > 0)) {
			event.preventDefault()
			return (event.returnValue =
				"Du har bilder tillagda på en eller flera ordrar, dessa förloras om du laddar om sidan. Är du säker?")
		}
		return
	}, [])

	useEffect(() => {
		const element = document.getElementById("mainLayoutPageContent")
		if (element) {
			element.scrollTop = 0
		}
	}, [])

	useEffect(() => {
		window.addEventListener("beforeunload", onConfirmRefresh, { capture: true })

		return () => {
			window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true })
		}
	}, [onConfirmRefresh])

	useEffect(() => {
		if (customerInformation == null || readOnlyMode) {
			return
		}

		let idAndVersionEqual = isEqual(lastRequestedIdAndVersionRef.current, checkoutSessionIdAndVersion)
		// For whatever reason, when the value is saved to the ref it removes the zoneId key if it's undefined, meaning that the comparison
		// Is basically {zoneId: undefined} == {}, which fails...
		let orderItemsEqual = isEqual(
			lastOrderItems.current?.map((obj) => {
				if (obj.zoneId === undefined) {
					delete obj.zoneId
				}

				return obj
			}),
			basket.orderItems.map((obj) => {
				if (obj.zoneId === undefined) {
					delete obj.zoneId
				}

				return obj
			}),
		)
		let customerInformationEqual = isEqual(lastCustomerInformation.current, customerInformation)

		if (idAndVersionEqual && orderItemsEqual && customerInformationEqual) {
			//Data hasn't changed, no point in setting up a checkout session again
			return
		}
		lastRequestedIdAndVersionRef.current = checkoutSessionIdAndVersion
		lastOrderItems.current = basket.orderItems
		lastCustomerInformation.current = customerInformation
		setCheckoutSessionLoading(true)
		setupCheckoutSession(
			client,
			checkoutSessionIdAndVersion,
			basket.orderItems,
			customerInformation,
			consumer,
			lets(basket.values.currentDiscountCode, (it) => it.identifier),
		).then((response) => {
			if (!response) {
				logger.debug("None/empty SetupCheckoutSessionResponse")
				metric("checkout-no-checkout-session", client)
				removeCheckoutSessionIdAndVersion()
				setKlarnaSession(null)
				setCheckoutSessionLoading(false)
				return
			}

			if (response === "VersionConflict") {
				logger.debug("VersionConflict for SetupCheckoutSession")
				metric("checkout-version-conflict", client)
				setCheckoutSessionIdAndVersion(newCheckoutSessionIdAndVersion())
				return
			}

			let newVersionAndId = {
				id: response.id,
				version: response.version,
			}
			if (!isEqual(lastRequestedIdAndVersionRef.current, newVersionAndId)) {
				setCheckoutSessionIdAndVersion(newVersionAndId)
				lastRequestedIdAndVersionRef.current = newVersionAndId
			}

			const newKlarnaSession = response.klarnaSession
			setCheckoutSessionLoading(false)

			if (newKlarnaSession) {
				try {
					;(window as any).Klarna.Payments.init({
						client_token: newKlarnaSession.clientToken,
					})

					if (!isEqual(klarnaSession, newKlarnaSession)) {
						metric("checkout-klarna-payments-init-ok", client)
						setKlarnaSession(newKlarnaSession)
					}
				} catch (e) {
					metric("checkout-klarna-payments-init-error", client)
					logger.error("klarna init error", e)
					// We may have a session here, but for whatever reason it's invalid
					// So we set klarna failed to true instead of nulling session
					// That way we can show more relevant things on the UI
					setKlarnaFailed(true)
				}
			} else {
				logger.debug("No KlarnaSession")
				metric("checkout-no-klarna-session", client)
				removeCheckoutSessionIdAndVersion()
				setKlarnaSession(null)
			}
		})
	}, [
		readOnlyMode,
		checkoutSessionIdAndVersion,
		client,
		customerInformation,
		klarnaSession,
		removeCheckoutSessionIdAndVersion,
		setCheckoutSessionIdAndVersion,
		basket.values.currentDiscountCode,
	])

	useEffect(() => {
		if (klarnaSession) {
			try {
				;(window as any).Klarna.Payments.load(
					{
						container: "#klarna-payments-container",
						payment_method_category: "klarna",
					},
					(res: { show_form: boolean; error: any }) => {
						if (!res.show_form) {
							metric("klarna-load-fail", client)
							// If show_form == false it means klarna isn't able to offer the user payments through their systems
							setKlarnaFailed(true)
							if (res.error) {
								logger.error("klarna-load-fail", res.error)
							}
						} else {
							metric("klarna-load-ok", client)
						}
					},
				)
			} catch (e) {
				logger.error("klarna load error", e)
				metric("klarna-load-exception", client)
				// We definitely have a session, but something went wrong when loading, so we disable the option
				setKlarnaFailed(true)
			}
		}
	}, [klarnaSession])

	useEffect(() => {
		const indexParam = queryParams.get(modalOpenOrderItemIndexKey)
		const orderItemIndexRaw = indexParam ? parseInt(indexParam, 10) : undefined
		const orderItemIndex = indexWithinBounds(orderItemIndexRaw, basket.orderItems)

		const modalOpenParam = queryParams.get(modalOpenQueryKey) as OpenModalTypes | null
		if (modalOpenParam) {
			when(modalOpenParam, {
				[OpenModalTypes.NewProject]: () => {},
				[OpenModalTypes.OldProject]: () => {},
				[OpenModalTypes.OrderItemEditDate]: () => {},
				[OpenModalTypes.OrderItemEditTime]: () => {},
				[OpenModalTypes.OrderItemEditProject]: () => {},
				[OpenModalTypes.OrderItemEditExactDeliveryLocation]: () => {
					if (!client.features.allowExactOrderDeliveryLocation) {
						return
					}

					if (orderItemIndex !== null) {
						const item = basket.orderItems[orderItemIndex]
						if (!addExactDelivery && item) {
							setAddExactDelivery(item)
						}
					}
				},
			})
		}
	}, [queryParams])

	useEffect(() => {
		const typeOfClosedModal = (previousQueryParams.current?.get(modalOpenQueryKey) || null) as OpenModalTypes | null
		if (
			typeOfClosedModal === OpenModalTypes.OrderItemEditExactDeliveryLocation &&
			!queryParams.has(modalOpenQueryKey)
		) {
			setAddExactDelivery(null)
		}

		previousQueryParams.current = new URLSearchParams(queryParams)
	}, [queryParams, setQueryParams])

	const updateSetCommentForTransporterMap = (k: string, v: string) => {
		if (readOnlyMode) {
			return
		}

		setCommentForTransporter(new Map(commentForTransporter.set(k, v)))
	}

	const updateCollapsedOrderItems = (k: string, v: boolean) => {
		setCollapsedOrderItems(new Map(collapsedOrderItems.set(k, v)))
	}

	const [collapsedOrderItems, setCollapsedOrderItems] = useState<Map<string, boolean>>(() => {
		if (basket.orderItems.length === 1) {
			const item = basket.orderItems[0]

			if (item) {
				return new Map([[item.id, true]])
			}
		}
		return new Map()
	})

	const onSubmit = async () => {
		setIsSubmitting(true)
		setErrorSubmitting(null)
		let consumerInformation: Consumer

		if (!customerInformation) {
			return
		}

		const submit = (paymentMethod: PaymentMethod) => {
			if ("personNumber" in customerInformation) {
				consumerInformation = {
					type: "Private",
					name: customerInformation.name,
					personNumber: customerInformation.personNumber || null,
					email: customerInformation.email || null,
					phone: customerInformation.phone || null,
					ordererName: customerInformation.ordererName || null,
				}
			} else {
				consumerInformation = {
					type: "Company",
					name: customerInformation.companyName,
					orgNumber:
						customerInformation.organizationNumber !== undefined &&
						customerInformation.organizationNumber !== ""
							? customerInformation.organizationNumber
							: null,
					email: customerInformation.email || null,
					phone: customerInformation.phone || null,
					ordererName: customerInformation.ordererName || null,
				}
			}

			let consumerId: string | null = null
			if (auth.Me) {
				consumerId =
					exhaustive(auth.Me, "type", {
						Client: () => {
							return clientSelectedConsumerId
						},
						Consumer: () => {
							return selectedConsumerId
						},
					}) || null
			}

			let newOrderItems: NewOrder[] = basket.orderItems
				.map((orderItem: OrderItem) => {
					let orderProducts = createNewOrderProductsFromOrderItemProducts(
						orderItem.products as OrderItemProduct[],
						client,
					)

					if (!orderItem.date || !orderItem.time) {
						return null
					}

					let contacts: NewOrderContact[] = []
					if (isArray(orderItem.project.contactPersons) && orderItem.project.contactPersons.length > 0) {
						contacts = orderItem.project.contactPersons.map((x) => ({ name: x.name, phone: x.phone }))
					} else if (orderItem.project.contactName && orderItem.project.contactPhone) {
						contacts = [
							{
								name: orderItem.project.contactName,
								phone: orderItem.project.contactPhone,
							},
						]
					} else if (consumerCatalog.features.projectMode === ConsumerProjectMode.FullProject) {
						return null
					}

					const ret: NewOrder = {
						clientSideKey: orderItem.id,
						marking: orderItem.project.marking ?? "",
						address: {
							street: orderItem.project.street,
							zipcode: orderItem.project.zipcode || null,
							city: orderItem.project.city,
							country: null,
							coordinates: orderItem.project.coordinates || null,
						},
						contacts: contacts,
						date: getNewOrderDate(orderItem.date),
						dateSlot: getNewOrderDateSlot(orderItem.date),
						time: {
							timeSlotId: orderItem.time.timeslotId,
							timeExact: orderItem.time.specificTime ? orderItem.time.timeValue : null,
						},
						expectedArticles: orderItem.articles?.toExpectedArticles() || [],
						products: orderProducts,
						comment: commentForTransporter.get(orderItem.id) || "",
						execution: {
							deliveryCoordinates: orderItem.project.deliveryCoordinates || null,
						},
						files:
							keys(selectedImages.current[orderItem.id]).length > 0
								? map(selectedImages.current[orderItem.id], (x) => ({
										id: x.imageName,
										type: "NewOrderFileImage",
								  }))
								: null,
						transportId: orderItem.transportId || null,
					}

					return ret
				})
				.filter((x) => x !== null) as NewOrder[]

			const order: NewOrderRequest = {
				consumerId: consumerId,
				consumer: consumerInformation,
				payment: {
					paymentMethod: paymentMethod,
				},
				licenseAgreement: createLicenseAgreementRequestData(),
				orders: newOrderItems,
				discountCodeIdentifier: lets(basket.values.currentDiscountCode, (it) => it.identifier),
			}

			API.postWithRetries<NewOrderResponse>(`/order-ui/orders-v1/${client.identifier}`, order, undefined, 10)
				.then((data) => {
					metric("order-successful", client)
					logger.debug("Successfully posted a new order")
					// Clear order items for client
					localStorage.setItem(`${client.identifier}.order-items`, JSON.stringify([]))
					removeCheckoutSessionIdAndVersion()
					setKlarnaSession(null)
					selectedImages.current = {}
					window.removeEventListener("beforeunload", onConfirmRefresh, { capture: true })
					setTimeout(() => {
						//Executed in next "tick" to avoid race condition with routes due to clearing of the basket
						navigator.open(`order/checkout/completed/${data.anonymousId}`, undefined, true)
					})
				})
				.catch((error: ServerError<any>) => {
					metric("order-failed", client)
					setIsSubmitting(false)
					if (error?.data?.message && error.data.message === "discount_code_has_expired") {
						setErrorSubmitting({ type: OrderConfirmErrorState.DiscountError })
					} else if (error?.data?.message && error.data.message?.startsWith("client_side_key_collision")) {
						setErrorSubmitting({
							type: OrderConfirmErrorState.ClientSideKeyCollision,
							id: error?.data?.message?.split("|")?.[1] || "",
						})
					} else {
						setErrorSubmitting({ type: OrderConfirmErrorState.RegularError })
					}
				})
		}

		if (consumerCatalog.pricesEnabled && usePayWithKlarna) {
			try {
				;(window as any).Klarna.Payments.authorize(
					{
						payment_method_category: "klarna",
					},
					undefined,
					(resp: KlarnaAuthCallbackData) => {
						if (resp.approved && resp.show_form) {
							// All good
							metric("klarna-authorize-ok", client)
							submit({ type: "KlarnaPayments", authorizationToken: resp.authorization_token })
						} else if (!resp.approved && resp.show_form) {
							// User probably closed the klarna checkout window before completing
							setIsSubmitting(false)
						} else {
							// Something went wrong, or the user wasn't authorized by klarna for some reason
							// so we go back to state before user clicked submit button and disable the klarna option
							metric("klarna-authorize-fail", client)
							setIsSubmitting(false)
							setKlarnaFailed(true)
							setUsePayWithKlarna(false)
						}

						if (isPlainObject(resp.error) && Object.keys(resp.error).length > 0) {
							logger.error("klarna auth callback error", resp.error)
						}
					},
				)
			} catch (e) {
				logger.error("klarna auth threw error", e)
				metric("klarna-authorize-exception", client)
				// Auth threw error for whatever reason, so we go back to state before user clicked submit button
				// But we also disable the klarna option
				setIsSubmitting(false)
				setKlarnaFailed(true)
				setUsePayWithKlarna(false)
			}
		} else {
			submit({ type: "Other" })
		}
	}

	function getNewOrderDate(date: OrderItemDateType): string | null {
		if (isString(date)) {
			return date || null
		}

		if (date?.dateSlotId && date.specificDate) {
			return date.specificDate
		}

		return null
	}

	function getNewOrderDateSlot(date: OrderItemDateType): NewOrderDate | null {
		if (!date || isString(date)) {
			return null
		}

		return {
			dateSlotId: date.dateSlotId,
		}
	}

	function onAddExactDeliveryLocation(project: Project | GetProject) {
		if (readOnlyMode) {
			return
		}

		if ("id" in project || !addExactDelivery || !project.deliveryCoordinates) {
			return
		}
		const map = orderItemToMapReference.current[addExactDelivery.id]

		if (map) {
			const newItems = cloneDeep(basket.orderItems)
			const oldMarkers = orderItemToMarkerReference.current[addExactDelivery.id]

			if (isArray(oldMarkers)) {
				oldMarkers.forEach((x) => (x.map = null))
			}
			orderItemToMarkerReference.current[addExactDelivery.id] = []
			const newMarkers = []

			const orderItemIndex = newItems.findIndex((x) => x.id === addExactDelivery.id)
			if (orderItemIndex > -1) {
				const orderItem = newItems[orderItemIndex]
				if (orderItem) {
					orderItem.project = project
					newItems[orderItemIndex] = orderItem
					basket.functions.setOrderItems([...newItems])
				}
			}

			newMarkers.push(...addAddressAndExactDeliveryMarkersToMap(map, project))

			orderItemToMarkerReference.current[addExactDelivery.id] = newMarkers

			setTimeout(() => {
				maybeSetMapBoundsForExactDelivery(map, project)
			}, 20)

			setAddExactDelivery(null)
		}
	}

	function removeExactDeliveryFromOrderItem(orderItemId: string) {
		const newOrderItems = cloneDeep(basket.orderItems)
		const newOrderItem = newOrderItems.find((x) => x.id === orderItemId)
		if (newOrderItem) {
			const map = orderItemToMapReference.current[newOrderItem.id]

			if (map) {
				const oldMarkers = orderItemToMarkerReference.current[newOrderItem.id]
				if (isArray(oldMarkers)) {
					oldMarkers.forEach((x) => (x.map = null))
				}
				newOrderItem.project.deliveryCoordinates = undefined
				basket.functions.setOrderItems(newOrderItems)
				const newMarkers = []
				newMarkers.push(...addAddressAndExactDeliveryMarkersToMap(map, newOrderItem.project))
				orderItemToMarkerReference.current[newOrderItem.id] = newMarkers
				map.setZoom(19)
			}
		}
	}

	function addImages(e: ChangeEvent<HTMLInputElement>, orderItem: OrderItem) {
		e.preventDefault()

		if (readOnlyMode) {
			return
		}

		if (e.target.files && e.target.files.length > 0) {
			const fileList: FileList = e.target.files
			let imagesAdded: number = 0
			const maxAllowedToAdd =
				keys(selectedImages.current[orderItem.id]).length > 0
					? 5 - keys(selectedImages.current[orderItem.id]).length
					: 5
			for (let i = 0; i < fileList.length && i < maxAllowedToAdd; i++) {
				const file = fileList.item(i)

				if (!isNull(file)) {
					const reader = new FileReader()
					reader.readAsDataURL(file as Blob)
					reader.onload = async () => {
						const res = reader.result
						if (!isNull(res) && (res as string)) {
							const resSplit = split(res as string, ",", 10)
							const res0 = resSplit[0]
							const res1 = resSplit[1]
							if (res0 && res1) {
								const cleanMime = cleanMimeString(res0)
								getImageURLFromBase64(res1, cleanMime)
									.then((res) => {
										const tooBig = file.size > maxImageSize
										const newImage: SelectedFile = {
											id: v4(),
											originalName: file.name,
											imageName: file.name,
											url: res,
											file: file,
											uploading: !tooBig,
											uploaded: false,
											uploadFailed: false,
											tooBig: tooBig,
											type: "Image",
										}
										let selectedImagesRef = selectedImages.current[orderItem.id]
										if (!selectedImagesRef) {
											selectedImagesRef = {}
										}
										selectedImagesRef[newImage.id] = newImage
										selectedImages.current[orderItem.id] = selectedImagesRef
										if (!newImage.tooBig) {
											uploadImage(orderItem.id, newImage.id)
										}
										imagesAdded++
									})
									.then(() => {
										if (imagesAdded === fileList.length || imagesAdded === maxAllowedToAdd) {
											setTick((tick) => tick + 1)
										}
									})
							}
						}
					}
					reader.onerror = (error) => {
						logger.log("Error: ", error)
					}
				}
			}
		}
	}

	function uploadImage(orderItemId: string, imageId: string) {
		const selectedFile = selectedImages.current[orderItemId]?.[imageId]

		if (!selectedFile || !selectedFile.file) {
			return
		}

		selectedFile.uploading = true
		selectedFile.uploadFailed = false
		setTick((tick) => tick + 1)

		const newForm = new FormData()
		newForm.append("image", selectedFile.file)
		API.postRaw<string>(`/order-ui/order-images-v1/${client.identifier}/upload-temp`, newForm)
			.then((res) => {
				selectedFile.uploading = false
				selectedFile.uploaded = true
				selectedFile.imageName = res
				setTick((tick) => tick + 1)
			})
			.catch(() => {
				selectedFile.uploading = false
				selectedFile.uploadFailed = true
				setTick((tick) => tick + 1)
			})
	}

	function orderItemImageUploadPart(orderItem: OrderItem): JSX.Element | null {
		if (!client.features.orderUiAllowUploadingOrderImages || readOnlyMode) {
			return null
		}

		return (
			<>
				<div className={style.addOrderItemImagesWrapper}>
					<span>
						Ladda upp en bild <span style={{ fontSize: "14px", fontWeight: "100" }}>(max 5 st)</span>
					</span>
					{keys(selectedImages.current[orderItem.id]).length < 5 ? (
						<div className={style.fileInput}>
							<label htmlFor={orderItem.id + "fileInput"}>Lägg till</label>

							<input
								id={orderItem.id + "fileInput"}
								type="file"
								accept=".png,.jpeg,.jpg"
								multiple={true}
								onChange={(e) => {
									addImages(e, orderItem)
								}}
							/>
						</div>
					) : null}
				</div>
				{keys(selectedImages.current[orderItem.id]).length > 0 ? (
					<div>
						<SbtH4>Valda bilder</SbtH4>
						<div className={style.orderItemSelectedImages}>
							{map(selectedImages.current[orderItem.id], (selectedImage) => {
								return (
									<div key={v4()}>
										<div className={style.imageWrapper}>
											<div
												className={style.error}
												style={{ display: selectedImage.uploadFailed ? "flex" : "none" }}>
												<FontAwesomeIcon
													icon={faArrowRotateRight}
													onClick={() => {
														uploadImage(orderItem.id, selectedImage.id)
													}}
												/>
											</div>
											<TrashIcon
												onClick={() => {
													if (isSubmitting) {
														return
													}

													if (selectedImages.current[orderItem.id]?.[selectedImage.id]) {
														delete selectedImages.current[orderItem.id]?.[selectedImage.id]
														setTick((tick) => tick + 1)
													}
												}}
												size={26}
												className={style.imageDeleteIconWrapper}
												iconClassName={style.imageDeleteIcon}
											/>
											<div
												className={style.loader}
												style={{ display: selectedImage.uploading ? "flex" : "none" }}>
												<FontAwesomeIcon spin={true} icon={faSpinner} />
											</div>

											<img
												alt={""}
												src={selectedImage.url}
												onClick={() => {
													window.open(selectedImage.url, "_blank")
												}}
											/>
										</div>
										{selectedImages.current[orderItem.id]?.[selectedImage.id]?.uploadFailed === true ? (
											<div className={style.errorText}>Uppladdning misslyckades</div>
										) : null}
										{selectedImages.current[orderItem.id]?.[selectedImage.id]?.tooBig === true ? (
											<div className={style.errorText}>Filen är för stor, max 10MB</div>
										) : null}
									</div>
								)
							})}
						</div>
					</div>
				) : null}

				<br />
			</>
		)
	}

	function createLicenseAgreementRequestData(): OrderLicenseAgreements {
		let licenseAgreement: OrderLicenseAgreements = {
			skrappy: acceptSkrappyTerms ? AgreementOptions.AGREE : AgreementOptions.DECLINED,
		}

		if (client.clientInfo.licenseAgreement.length > 0) {
			licenseAgreement.client = acceptCustomerTerms ? AgreementOptions.AGREE : AgreementOptions.DECLINED
		}

		licenseAgreement.dynamic = client.dynamicAgreements?.agreements?.map((agreement): DynamicOrderAgreement => {
			return exhaustive(agreement, "type", {
				[DynamicAgreementTypes.URL]: (it) => {
					const state = dynamicAgreementsState[it.title]
					return {
						type: DynamicOrderAgreementTypes.URL,
						url: it.url,
						value: state ?? false ? AgreementOptions.AGREE : AgreementOptions.DECLINED,
					}
				},
				[DynamicAgreementTypes.Text]: (it) => {
					const state = dynamicAgreementsState[it.text]
					return {
						type: DynamicOrderAgreementTypes.Text,
						text: it.text,
						value: state ?? false ? AgreementOptions.AGREE : AgreementOptions.DECLINED,
					}
				},
			})
		})

		return licenseAgreement
	}

	function termsAccepted() {
		const acceptedAllDynamic =
			client.dynamicAgreements?.agreements
				?.map((agreement) => {
					return exhaustive(agreement, "type", {
						[DynamicAgreementTypes.URL]: (it) => {
							return dynamicAgreementsState[it.title] ?? false
						},
						[DynamicAgreementTypes.Text]: (it) => {
							return dynamicAgreementsState[it.text] ?? false
						},
					})
				})
				?.reduce((previousValue, currentValue) => previousValue && currentValue, true) ?? true

		const customerTerms = acceptCustomerTerms || client.clientInfo.licenseAgreement.length <= 0
		return acceptSkrappyTerms && customerTerms && acceptedAllDynamic
	}

	function submitDisabled() {
		const imagesInvalid = some(selectedImages.current, (x) => some(x, (x) => x.uploading || x.tooBig || x.uploadFailed))

		return (
			isSubmitting ||
			checkoutSessionLoading ||
			!termsAccepted() ||
			!customerInformation ||
			imagesInvalid ||
			(consumerCatalog.pricesEnabled && klarnaSession && !klarnaFailed ? !usePayWithKlarna : false)
		)
	}

	function orderItemProductImageElement(
		orderItemProductCategory: ProductCategoryInstance,
		orderItemProduct: OrderItemProduct,
	) {
		const image = orderItemProductCategory.products[orderItemProduct.productId]?.images?.main
		if (image && "url" in image) {
			return (
				<div
					style={{
						display: "flex",
						height: "100%",
						maxHeight: "80px",
					}}
					className={style.basketItemProductImage}>
					<img
						style={{
							maxWidth: "100%",
							height: "100%",
							margin: "0 auto",
							objectFit: "contain",
						}}
						src={image.url}
						alt="Produktbild"
					/>
				</div>
			)
		}

		return (
			<ProductImage
				client={client}
				categoryName={orderItemProduct.category}
				image={image ? ProductImageType[image.typeImage] : undefined}
				wrapperClassName={style.basketItemProductImage}
			/>
		)
	}

	function recreateOrderItemWithNewTransport(orderItem: OrderItem, newTransportId: string) {
		basket.functions.setSelectedOrderItem(null)
		basket.functions.setSelectedProject(null)
		setTimeout(() => {
			basket.functions.recreateOrderItemWithNewTransport(orderItem, newTransportId)
			setShowConfirmChangeTransportationModal(null)
		}, 100)
	}

	function selectAlternativeTransportSection(orderItemId: string, transportId: string) {
		if (readOnlyMode) {
			return null
		}

		const transport = client.possibleTransportations[transportId]

		if (!transport) {
			return null
		}

		const alts = (transport.alternatives || []).filter(
			(x) => x.showAsSelector === ProductTransportationSelectionType.CheckoutSelector,
		)

		if (alts.length === 0) {
			return null
		}

		const options = map(alts, (alt) => {
			if (!alt) {
				return null
			}
			const name = client.possibleTransportations[alt.id]?.name || ""
			return { key: alt.id, value: name }
		}).filter(notNull)

		if (options.length === 0) {
			return null
		}

		const current = {
			key: transportId,
			value: client.possibleTransportations[transportId]?.name || "",
		}

		let selectedValue = transportId

		if (showConfirmChangeTransportationModal && showConfirmChangeTransportationModal.orderItemId === orderItemId) {
			selectedValue = showConfirmChangeTransportationModal.newValue
		}
		return (
			<React.Fragment key={orderItemId + "_alternatives_transport"}>
				<div className={style.alternativeTransportHeader}>Alternativ transportmetod</div>
				<MultiStateSwitch
					selectedValue={selectedValue}
					onSelect={(option, initial) => {
						if (initial || option.key === selectedValue) {
							return
						}
						setShowConfirmChangeTransportationModal({
							orderItemId: orderItemId,
							oldValue: transportId,
							newValue: option.key,
						})
					}}
					options={[current].concat(options)}
				/>
			</React.Fragment>
		)
	}

	function confirmModalBeforeAndAfterContent(): JSX.Element | null {
		if (!showConfirmChangeTransportationModal) {
			return null
		}

		const item = basket.orderItems.find((x) => x.id === showConfirmChangeTransportationModal.orderItemId)
		const oldTransport = client.possibleTransportations[showConfirmChangeTransportationModal.oldValue]
		const newTransport = client.possibleTransportations[showConfirmChangeTransportationModal.newValue]

		if (item && oldTransport && newTransport) {
			return (
				<div className={style.beforeAndAfter}>
					<div>
						<div className={style.transportName}>
							Från: <strong>{oldTransport.name}</strong>
						</div>
						<TransportDescription
							type={oldTransport.transportationDetails.type}
							data={getDataForTransportSvg(oldTransport)}
						/>
					</div>
					<div className={style.navigationArrowWrapper}>
						<ArrowRightIcon iconClassName={style.navigationArrow} size={32} />
					</div>
					<div>
						<div className={style.transportName}>
							Till: <strong>{newTransport.name}</strong>
						</div>
						<TransportDescription
							type={newTransport.transportationDetails.type}
							data={getDataForTransportSvg(newTransport)}
						/>
					</div>
				</div>
			)
		}

		return null
	}

	function confirmChangeTransportModal() {
		let text = ""

		if (consumerCatalog.pricesEnabled) {
			text =
				"Om du ändrar detta värde så genereras varukorgen om på nytt. " +
				"Antal ordrar kan bli fler eller färre, beroende på valt alternativ om det finns matchande ordrar med samma transport. " +
				"Det totala priset kan också skilja sig från vad det är för nuvarande."
		} else {
			text =
				"Om du ändrar detta värde så genereras varukorgen om på nytt. " +
				"Antal ordrar kan bli fler eller färre, beroende på valt alternativ om det finns matchande ordrar med samma transport."
		}

		return (
			<>
				{showConfirmChangeTransportationModal ? (
					<ConfirmModal
						text={text}
						customContent={confirmModalBeforeAndAfterContent()}
						onClose={(reason) => {
							if (reason === ConfirmModalCloseReason.Accept) {
								const item = basket.orderItems.find(
									(x) => x.id === showConfirmChangeTransportationModal.orderItemId,
								)

								if (item) {
									recreateOrderItemWithNewTransport(item, showConfirmChangeTransportationModal.newValue)
								}
							} else {
								setShowConfirmChangeTransportationModal(null)
							}
						}}
					/>
				) : null}
			</>
		)
	}

	function backArrowAndProgressBarSection() {
		return (
			<div className={style.backArrowAndProgressBar}>
				<span className={style.backArrow} onClick={() => navigator.open("order")}>
					<ArrowLeftIcon size={28} />
				</span>
				<ul className={style.timeline}>
					<li className={style.complete}>
						<div />
					</li>
					<div className={style.timelineLineComplete} />
					<li className={style.complete}>
						<div />
					</li>
					<div className={style.timelineLineComplete} />
					<li className={style.current}>
						<div />
					</li>
				</ul>
				<div />
			</div>
		)
	}

	function orderItemsSection() {
		return (
			<div className={style.reviewSectionsWrapper}>
				<div className={style.sectionHeader}>
					<h1>Granska och komplettera</h1>
					<Mbact>Se till att allt stämmer & lämna kompletterande uppgifter för din order</Mbact>
				</div>

				{!readOnlyMode ? (
					<div className={style.reviewSection}>
						<SbtH2 styles={{ marginBottom: "10px", wordBreak: "break-all" }}>Kunduppgifter</SbtH2>
						<OrderConfirmCustomerInformation
							onSelectedBefore={() => {
								setCheckoutSessionLoading(true)
							}}
							onSelected={(x) => {
								setCustomerInformation(x || null)
							}}
						/>
					</div>
				) : null}

				{basket.orderItems.map((orderItem, index) => {
					return orderItemContent(orderItem, index)
				})}
			</div>
		)
	}

	function orderItemContent(orderItem: OrderItem, index: number) {
		const clientSideKeyCollision =
			errorSubmitting?.type === OrderConfirmErrorState.ClientSideKeyCollision && errorSubmitting.id === orderItem.id

		return (
			<div
				key={orderItem.id + "_orderitem"}
				className={cls(style.reviewSection, {
					[style.clientSideKeyCollision]: clientSideKeyCollision,
				})}>
				{clientSideKeyCollision ? (
					<div style={{ color: "var(--invalid-color)", fontSize: "24px", fontWeight: 600 }}>DUBLETT</div>
				) : null}
				<SbtH2 styles={{ marginBottom: "10px", wordBreak: "break-all" }}>Leveransinformation</SbtH2>
				{orderItemAddressAndMinimapContent(orderItem)}
				{orderItemExactDeliveryLocationContent(orderItem, index)}
				{orderItemDateAndTimeContent(orderItem)}
				{orderItemZoneElement(orderItem, client, style.orderItemZone)}
				<br />

				<div className={style.articleTextWrapper}>
					<SbtH2 styles={{ wordBreak: "break-all" }}>Beställningsöversikt</SbtH2>
				</div>
				{orderItemProductsCollapseBar(orderItem)}
				{orderItemProductsContent(orderItem)}
				<br />
				{selectAlternativeTransportSection(orderItem.id, orderItem.transportId)}
				{orderItemCommentSection(orderItem)}
				<br />
				{orderItemImageUploadPart(orderItem)}
			</div>
		)
	}

	function orderItemAddressAndMinimapContent(orderItem: OrderItem) {
		const contactInfoOneLine = contactInformationOneLineText(orderItem.project, true)

		return (
			<div className={style.addressAndMiniMapWrapper}>
				<div className={style.address}>
					<strong>{orderItem.project.street}, </strong>
					{orderItem.project.zipcode ? orderItem.project.zipcode + " " : ""}
					{orderItem.project.city}
				</div>
				{orderItem.project.marking ? (
					<div
						className={style.markingAndContactInfoText}
						style={{
							marginTop: "10px",
							display: "flex",
							gap: "10px",
							alignItems: "center",
						}}>
						<MarkingIcon size={22} />
						<span>
							<strong>{orderItem.project.marking}</strong>
						</span>
					</div>
				) : null}
				{contactInfoOneLine ? (
					<div
						className={style.markingAndContactInfoText}
						style={{
							marginTop: "10px",
							display: "flex",
							gap: "10px",
							alignItems: "center",
						}}>
						<KontaktIcon className={style.contactIcon} size={22} />
						<span>{contactInfoOneLine}</span>
					</div>
				) : null}
				<hr className={style.mapAndAddressDelimiter} />
				{isGoogleMapsLoaded ? (
					<GoogleMapComponent
						mapContainerClassName={cls(style.mapContainer)}
						center={orderItem.project.coordinates}
						zoom={19}
						onLoad={(map) => {
							if (isArray(orderItemToMarkerReference.current[orderItem.id])) {
								orderItemToMarkerReference.current[orderItem.id]?.forEach((x) => (x.map = null))
							}
							const newMarkers = []
							newMarkers.push(...addAddressAndExactDeliveryMarkersToMap(map, orderItem.project))
							orderItemToMarkerReference.current[orderItem.id] = newMarkers
							if (orderItem.project.deliveryCoordinates) {
								maybeSetMapBoundsForExactDelivery(map, orderItem.project)
							}

							orderItemToMapReference.current[orderItem.id] = map
						}}
						options={googleMiniMapOptions()}
					/>
				) : null}
			</div>
		)
	}

	function orderItemExactDeliveryLocationContent(orderItem: OrderItem, index: number) {
		if (!client.features.allowExactOrderDeliveryLocation || readOnlyMode) {
			return null
		}

		return (
			<div className={style.addExactDeliveryWrapper}>
				<span>
					{orderItem.project.deliveryCoordinates ? (
						<>Önskad exakt leveransplats tillagd</>
					) : (
						<>Lägg till önskad exakt leveransplats</>
					)}
				</span>
				{orderItem.project.deliveryCoordinates ? (
					<div className={style.editExactDeliveryActions}>
						<PenIcon
							size={22}
							className={style.moduleBoxColor}
							iconClassName={style.moduleBoxIconColorStroke}
							onClick={() => {
								queryParams.set(modalOpenQueryKey, OpenModalTypes.OrderItemEditExactDeliveryLocation)
								queryParams.set(modalOpenOrderItemIndexKey, index.toString())
								setQueryParams(queryParams)
							}}
						/>
						<TrashIcon
							size={22}
							className={style.moduleBoxColor}
							iconClassName={cls(style.moduleBoxIconColorStroke, style.moduleBoxIconColorFill)}
							onClick={() => {
								removeExactDeliveryFromOrderItem(orderItem.id)
							}}
						/>
					</div>
				) : (
					<button
						onClick={() => {
							queryParams.set(modalOpenQueryKey, OpenModalTypes.OrderItemEditExactDeliveryLocation)
							queryParams.set(modalOpenOrderItemIndexKey, index.toString())
							setQueryParams(queryParams)
						}}>
						Lägg till
					</button>
				)}
			</div>
		)
	}

	function orderItemDateAndTimeContent(orderItem: OrderItem) {
		return (
			<div className={style.orderItemDateAndTimeWrapper}>
				<div className={style.orderItemDateAndTime}>
					<DatumIcon className={style.dateAndTimeInfoIcon} size={18} />
					{orderItemDateElementContent(client, orderItem.date)}
				</div>
				<div className={style.orderItemDateAndTime}>
					<FontAwesomeIcon className={style.timeIcon} icon={faClock} />
					<span>
						{orderItem.time
							? orderItem.time.specificTime
								? orderItem.time.timeValue
								: orderItem.time.timeName
							: null}
					</span>
				</div>
			</div>
		)
	}

	function orderItemProductsCollapseBar(orderItem: OrderItem) {
		if (orderItem.products.length === 1) {
			return null
		}

		return (
			<div
				className={style.orderItemProductToggleSection}
				style={{ cursor: "pointer" }}
				onClick={() => updateCollapsedOrderItems(orderItem.id, collapsedOrderItems.get(orderItem.id) !== true)}>
				<div>
					<div style={{ position: "relative", display: "flex" }}>
						{uniq(orderItem.products.map((x) => x.category)).map((categoryName, index) => {
							if (index < 3) {
								let category = client.categories[categoryName]

								if (!category) {
									return null
								}

								return (
									<CategoryCard
										key={category.id}
										category={{
											categoryImages: category.categoryImages,
											image: category.image,
										}}
										className={cls(
											style.productCategoryImage,
											style.orderItemProductToggleCategoryIcon,
										)}
										style={{
											left: (-(20 * index)).toString() + "px",
											zIndex: 100 - 10 * index,
										}}
									/>
								)
							}

							return null
						})}
					</div>
					<div style={{ color: "var(--module-box-text-color)" }}>
						<div style={{ fontWeight: 600 }}>
							{orderItem.products[0]?.category || ""}
							{uniq(orderItem.products.map((x) => x.category)).length > 1
								? " +" + (uniq(orderItem.products.map((x) => x.category)).length - 1).toString() + " till"
								: null}
						</div>
						<div style={{ fontSize: "14px" }}>{orderItem.products.length} objekt</div>
					</div>
				</div>
				<button type="button" className={style.basketCollapseButton}>
					{collapsedOrderItems.get(orderItem.id) === true ? (
						<FontAwesomeIcon
							className={style.basketCollapseButtonIcon}
							style={{ paddingTop: "2px" }}
							icon={faAngleDown}
						/>
					) : (
						<FontAwesomeIcon
							className={style.basketCollapseButtonIcon}
							style={{ paddingTop: "3px" }}
							icon={faAngleRight}
						/>
					)}
				</button>
			</div>
		)
	}

	function orderItemProductsContent(orderItem: OrderItem) {
		if (!(collapsedOrderItems.get(orderItem.id) === true || orderItem.products.length === 1)) {
			return null
		}

		return (
			<div>
				{(orderItem.products as OrderItemProduct[]).map((orderItemProduct) => {
					return orderItemSingleProductContent(orderItem, orderItemProduct)
				})}
			</div>
		)
	}

	function orderItemSingleProductContent(orderItem: OrderItem, orderItemProduct: OrderItemProduct) {
		let orderItemProductCategory = client.findCategoryByName(orderItemProduct.category)
		let orderItemCategory = client.categories[orderItem.category]
		if (!orderItemCategory || !orderItemProductCategory) {
			return null
		}
		let serviceName
		let serviceUnit
		let amount

		if (orderItemCategory.type === "WasteCategory" && orderItemProduct.serviceId) {
			let service = orderItemCategory.services[orderItemProduct.serviceId]
			serviceName = service?.name
			serviceUnit = service?.unit || ProductServiceUnit.Piece
			amount = getAmountOfProductsInOrderItemProduct(orderItemProduct, client)
		} else if (orderItemCategory.type === "GoodsCategory" && orderItemProduct.packagingMethod) {
			const method = client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]

			if (!method) {
				throwIllegalState(
					`tried to render order item during checkout with packaging method that doesn't exist, packagingMethodId: ${orderItemProduct.packagingMethod.id}`,
				)
			}

			serviceName = method.name
			serviceUnit = method.productUnit
			if (method.visualization.type === "Incrementor") {
				amount = orderItemProduct.packagingMethod.amount
			} else {
				amount = orderItemProduct.packagingMethod.amount * method.multiplier
			}
		}

		return (
			<div
				key={orderItem.id + orderItemProduct.productId + orderItemProduct.uniqueId}
				className={cls(style.orderConfirm, style.productInfoBox)}>
				<div className={style.productInfoBoxNameAndCategory}>
					<span className={style.productInfoBoxMarkingText}>
						<Mbt>{unitFormatter(orderItemProduct.name)}</Mbt>
					</span>
					<Mbt>{serviceName}</Mbt>
					{orderItemProduct.wasteType ? (
						<Mbt>{client.possibleWasteTypes[orderItemProduct.wasteType.wasteTypeId]?.name}</Mbt>
					) : null}
				</div>
				<div className={style.amountCellWrapper}>
					<span className={style.tableCellWrapper}>
						<span className={style.orderItemProductAmount}>
							{amount}
							&nbsp;
							{productServiceUnitToHumanText(serviceUnit)}
						</span>
					</span>
				</div>
				<div style={{ display: "table" }}>
					<span className={style.tableCellWrapper}>
						{orderItemProductImageElement(orderItemProductCategory, orderItemProduct)}
					</span>
				</div>
			</div>
		)
	}

	function orderItemCommentSection(orderItem: OrderItem) {
		if (readOnlyMode) {
			return null
		}

		return (
			<>
				<SbtH4 className={style.commentForClient}>Kommentar till leverantören</SbtH4>
				<div>
					<textarea
						key={orderItem.id + "_textarea"}
						onChange={(x) => {
							updateSetCommentForTransporterMap(orderItem.id, x.target.value)
						}}
						defaultValue={commentForTransporter.get(orderItem.id)}
						className={style.deliveryInfoInput}
						rows={4}
						placeholder="Exempel: Det står en kranbil i vägen för leverans, ring mig 20 minuter innan beräknad
									ankomst så flyttar vi på kranbilen."
					/>
				</div>
			</>
		)
	}

	function summaryAndConfirmSection() {
		return (
			<div>
				<div className={cls(style.sectionHeader, style.rightSide)}>
					<h1>Granska och komplettera</h1>
					<Mbact>Se till att allt stämmer & lämna kompletterande uppgifter för din order</Mbact>
				</div>

				<span className={style.stickyOnDesktop}>
					{pricesSummarySection()}
					{discountCodeSection()}
					{paymentMethodSection()}
					{termsAndConditionsSection()}
					{selfRegistrationSection()}
				</span>
			</div>
		)
	}

	function pricesSummarySection() {
		if (!consumerCatalog.pricesEnabled) {
			return null
		}

		return (
			<div className={style.summarySection}>
				<SbtH2 styles={{ marginBottom: "10px", wordBreak: "break-all" }}>Kvitto & summering</SbtH2>
				{customerInformation ? (
					<div className={style.customerInfoWrapper}>
						<div>
							<MbtH5 styles={{ marginBottom: "5px" }}>
								{"personNumber" in customerInformation
									? customerInformation.name
									: customerInformation.companyName}
							</MbtH5>
						</div>
						<div>
							{"personNumber" in customerInformation ? (
								<span className={style.customerInfo}>
									<div>{customerInformation.personNumber}</div>
									<div style={{ fontSize: "14px" }}>{customerInformation.email}</div>
									<div style={{ fontSize: "14px" }}>{customerInformation.phone}</div>
								</span>
							) : (
								<span className={style.customerInfo}>
									<div>{customerInformation.organizationNumber}</div>
									<div style={{ fontSize: "14px" }}>{customerInformation.email}</div>
									<div style={{ fontSize: "14px" }}>{customerInformation.phone}</div>
								</span>
							)}
						</div>
					</div>
				) : null}
				<div style={{ marginTop: "10px" }} />
				<OrderConfirmPriceSummarySection
					orderItems={basket.orderItems}
					totalPrices={basket.values.orderItemTotalPrices}
				/>
			</div>
		)
	}

	function discountCodeSection() {
		if (client.features.discountCodeMode === DiscountCodeMode.Hide || readOnlyMode) {
			return null
		}

		return (
			<DiscountCodeVerifier
				currentDiscountCode={basket.values.currentDiscountCode}
				addDiscountCode={(verifyDiscountCode) => {
					if (verifyDiscountCode) {
						basket.functions.setCurrentDiscountCode(verifyDiscountCode)
					} else {
						basket.functions.setCurrentDiscountCode(null)
					}
				}}
			/>
		)
	}

	function paymentMethodSection() {
		if (!consumerCatalog.pricesEnabled || readOnlyMode) {
			return null
		}

		return (
			<div className={cls(style.paymentContainer)}>
				<SbtH2 styles={{ wordBreak: "break-all" }}>Betalningsmetod</SbtH2>
				<hr />
				{paymentMethodSectionActualContent()}
			</div>
		)
	}

	function paymentMethodSectionActualContent() {
		if (!consumerCatalog.pricesEnabled || readOnlyMode) {
			return null
		}

		//Hack hack.. we need the customer information before we can create a checkout session, that is required for klarna.. so this component needs a partial render

		if (checkoutSessionLoading) {
			return (
				<div style={{ alignSelf: "center" }}>
					<Loader />
				</div>
			)
		}

		return (
			<>
				{klarnaSession ? (
					<div className={style.inputWrapper}>
						<div style={{ marginTop: "auto", marginBottom: "auto" }}>
							<input
								className={style.input}
								type="radio"
								id="klarna_select_input"
								defaultChecked={usePayWithKlarna}
								disabled={klarnaFailed}
								onChange={(e) => {
									setUsePayWithKlarna(e.target.checked)
								}}
							/>
						</div>
						<label
							style={{
								color: klarnaFailed ? "var(--disable-color)" : undefined,
							}}
							htmlFor="klarna_select_input">
							<strong>Klarna</strong>
							<br />
							<span style={{ fontWeight: 400 }}>Säkert och smidigt</span>
							{klarnaFailed ? <div style={{ color: "red" }}>Ej tillgängligt</div> : null}
						</label>
						<img
							style={{ marginLeft: "auto", height: "32px" }}
							src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
							alt="Klarna logga"
						/>
					</div>
				) : null}
				<div
					className={cls(style.klarnaContainer, {
						[style.visible]: usePayWithKlarna && !klarnaFailed && klarnaSession !== null,
					})}
					id="klarna-payments-container"></div>
				{klarnaFailed || !klarnaSession ? (
					<div className={style.inputWrapper}>
						<div style={{ marginTop: "auto", marginBottom: "auto" }}>
							<input className={style.input} type="radio" id="other_select_input" defaultChecked={true} />
						</div>
						<label htmlFor="other_select_input">
							<strong>Faktura</strong>
							<br />
							<span style={{ fontWeight: 400 }}>
								Leverantören skickar en faktura i efterhand, med reservation för potentiellt skiljande
								priser
							</span>
						</label>
					</div>
				) : null}
			</>
		)
	}

	function termsAndConditionsSection() {
		if (readOnlyMode) {
			return null
		}

		return (
			<div
				className={style.termsAndConditionsSection}
				style={{ marginTop: consumerCatalog.pricesEnabled ? "20px" : 0 }}>
				<span
					style={{
						fontSize: "20px",
						fontWeight: 500,
						display: "block",
						marginBottom: "15px",
						color: "var(--section-background-text-color)",
					}}>
					Godkänn villkor
				</span>
				<LicenseToggle
					title={"Skrappy solutions allmänna villkor"}
					url={skrappyTermsAndConditionsUrl}
					onCheck={setAcceptSkrappyTerms}
				/>
				{client.clientInfo.licenseAgreement.length > 0 ? (
					<LicenseToggle
						title={`${client.clientInfo.clientName} allmänna villkor`}
						url={client.clientInfo.licenseAgreement}
						onCheck={setAcceptCustomerTerms}
					/>
				) : null}
				{client.dynamicAgreements?.agreements?.map((agreement) => {
					return (
						<AgreementLicenseToggle
							agreement={agreement}
							onCheck={(key, check) => {
								setDynamicAgreementsState((prevState) => {
									return { ...prevState, [key]: check }
								})
							}}
							key={getKey(agreement)}
						/>
					)
				}) ?? null}
				<FinalizeButton
					disabled={submitDisabled()}
					type="button"
					onClick={() => {
						onSubmit()
					}}>
					{client.isDemo() ? "Godkänn demobeställning (genomförs ej på riktigt)" : "Godkänn beställning"}
					{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
				</FinalizeButton>
				{errorSubmitting != null
					? exhaustive(errorSubmitting, "type", {
							[OrderConfirmErrorState.DiscountError]: () => {
								return <SbtInvalid>Rabattkoden har gått ut</SbtInvalid>
							},
							[OrderConfirmErrorState.RegularError]: () => {
								return <SbtInvalid>Något gick fel, försök igen</SbtInvalid>
							},
							[OrderConfirmErrorState.ClientSideKeyCollision]: (it) => {
								return (
									<div
										style={{
											color: "var(--invalid-color)",
											fontSize: "20px",
											marginTop: "10px",
										}}>
										En order som du försöker skicka in finns redan registrerad i vårt system. Klicka{" "}
										<strong
											style={{ textDecoration: "underline", cursor: "pointer" }}
											onClick={() => {
												basket.functions.setOrderItems(
													basket.orderItems.filter((x) => x.id !== it.id),
												)
											}}>
											här
										</strong>{" "}
										för att ta bort ordern och försöka igen.
									</div>
								)
							},
					  })
					: null}
			</div>
		)
	}

	/**
	 * This is only shown when in readOnlyMode, meaning that we have self
	 * registration enabled, and we're not logged in. It takes you to the self
	 * registration page, and then (maybe) back again.
	 */
	function selfRegistrationSection() {
		if (!readOnlyMode) {
			return null
		}

		return (
			<div
				className={style.selfRegistrationSection}
				style={{ marginTop: consumerCatalog.pricesEnabled ? "20px" : 0 }}>
				<span className={style.title}>Bli kund</span>

				<div className={style.content}>
					<div style={{ fontSize: "22px", fontWeight: 600 }}>
						För att kunna lägga en order måste du vara kund hos oss.
					</div>
					<div>
						Har du redan ett konto?{" "}
						<span
							style={{ textDecoration: "underline", cursor: "pointer" }}
							onClick={() => {
								EventQueue.dispatchEvent("openLoginModal", { redirectTo: "order/checkout" })
							}}>
							<strong>Logga in</strong>
						</span>
					</div>
				</div>

				<FinalizeButton
					type="button"
					onClick={() => {
						if (
							client.features.consumerSelfRegistrationSettings.mode ===
							ConsumerSelfRegistrationMode.AllowCreatePending
						) {
							navigator.open("new-customer")
						} else {
							navigator.open("new-customer?returnToCheckout")
						}
					}}>
					<span style={{ marginRight: "10px" }}>Bli kund</span> <FontAwesomeIcon icon={faUserPlus} />
				</FinalizeButton>
			</div>
		)
	}

	return (
		<>
			{addExactDelivery ? (
				<ProjectInputModule
					inputProject={{ project: addExactDelivery.project, isNew: false }}
					onDone={onAddExactDeliveryLocation}
					onClose={() => {
						setAddExactDelivery(null)
						removeModalOpen(queryParams, setQueryParams)
					}}
					exactLocationMode={true}
				/>
			) : null}
			{confirmChangeTransportModal()}
			<div className={style.wrapper}>
				{backArrowAndProgressBarSection()}
				{orderItemsSection()}
				{summaryAndConfirmSection()}
			</div>
		</>
	)
}
