import { CSSProperties, FC, PropsWithChildren } from "react"
import style from "./SbtInvalid.module.css"

type Props = {
	styles?: CSSProperties
} & PropsWithChildren

/*SBT - 14pt invalid semibold */
export const SbtInvalid: FC<Props> = ({ children, styles }) => {
	return (
		<div className={style.wrapper} style={styles}>
			<span className={style.message}>{children}</span>
		</div>
	)
}
