import { exhaustive } from "exhaustive"
import { isString } from "lodash"
import React, { ReactNode, useContext } from "react"
import { z } from "zod"
import { useAuth } from "../Auth/AuthContext"
import { getLogger } from "../Logging/getLogger"
import { useBrandedLocalStorage } from "../Shared/useBrandedLocalStorage"
import { useClientContext } from "./ClientAndUserProvider"

const logger = getLogger("ConsumerContext")

export type ConsumerId = string & { type: "ConsumerId" }

export const SelectedConsumerUserSchema = z.object({
	id: z.string().optional(),
	name: z.string(),
	email: z.string().optional(),
	phone: z.string().optional(),
})
export type SelectedConsumerUser = z.input<typeof SelectedConsumerUserSchema>

export abstract class ConsumerInstance {
	constructor(consumerId: ConsumerId, consumerUser: ContextConsumerUser | null) {
		this.consumerId = consumerId
		this.consumerUser = consumerUser
	}

	readonly consumerId: ConsumerId
	readonly consumerUser: ContextConsumerUser | null
}

/**
 * Consumer selected by a Consumer User
 */
export class ConsumerByConsumerUser extends ConsumerInstance {}

/**
 * Consumer selected by a Client User
 */
export class ConsumerByClientUser extends ConsumerInstance {}

/**
 * A Consumer User used in the Context
 */
abstract class ContextConsumerUser {}

class ConsumerUserInstance extends ContextConsumerUser {
	readonly userId: string
	constructor(userId: string) {
		super()
		this.userId = userId
	}
}

class TemporaryConsumerUserInstance extends ContextConsumerUser {
	readonly temporaryUser: SelectedConsumerUser

	constructor(temporaryUser: SelectedConsumerUser) {
		super()
		this.temporaryUser = temporaryUser
	}
}

export type ConsumerContextType = ConsumerInstance | null

const ConsumerContext = React.createContext<ConsumerContextType>(null)

type Props = {
	children: ReactNode
}

function createClientSelectedConsumerUser(
	selectedUserLocalstorage: SelectedConsumerUser | string,
): ContextConsumerUser | null {
	if (!selectedUserLocalstorage) {
		return null
	}

	if (isString(selectedUserLocalstorage)) {
		return new ConsumerUserInstance(selectedUserLocalstorage)
	}

	return new TemporaryConsumerUserInstance(selectedUserLocalstorage)
}

export function ConsumerContextProvider({ children }: Props) {
	const brandRoot = useClientContext()?.identifier
	const auth = useAuth()

	if (!brandRoot) {
		throw Error("Tried to access client identifier in consumer context, but none was set")
	}

	const [selectedConsumerId, setSelectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [selectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	let consumer: ConsumerInstance | null
	const me = auth?.Me
	if (!me) {
		//I'm not logged in and have therefore not selected a consumer
		consumer = null
	} else {
		consumer = exhaustive(me, "type", {
			Consumer: () => {
				const exists = me.consumers.findIndex((x) => x.id === selectedConsumerId) > -1

				if (!exists) {
					setSelectedConsumerId(me.consumers?.[0]?.id || "")
					return null
				}

				if (selectedConsumerId) {
					let userInstance = new ConsumerUserInstance(me.userId)
					localStorage.setItem(`${brandRoot}.resolved-selected-consumer-id`, selectedConsumerId)
					return new ConsumerByConsumerUser(selectedConsumerId as ConsumerId, userInstance)
				}
				return null
			},
			Client: () => {
				const userInstance = createClientSelectedConsumerUser(selectedUserLocalstorage)

				if (clientSelectedConsumerId) {
					localStorage.setItem(`${brandRoot}.resolved-selected-consumer-id`, clientSelectedConsumerId)
					return new ConsumerByClientUser(clientSelectedConsumerId as ConsumerId, userInstance)
				}
				localStorage.removeItem(`${brandRoot}.resolved-selected-consumer-id`)
				return null
			},
		})
	}

	logger.debug("ConsumerContext: ", consumer)
	return <ConsumerContext.Provider value={consumer}>{children}</ConsumerContext.Provider>
}

export function useConsumer(): ConsumerContextType {
	return useContext(ConsumerContext)
}
