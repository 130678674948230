import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { debounce, isArray, isEqual, some, sortBy } from "lodash"
import { FieldModuleBox } from "Orders/Components/FieldModuleBox/FieldModuleBox"
import { FieldModuleBoxWrapper } from "Orders/Components/FieldModuleBox/FieldModuleBoxWrapper"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { ModulChooserButton } from "Orders/Components/ModulChooserButton/ModulChooserButton"
import { ModulePopup, ModulePopupProps } from "Orders/Components/ModulePopup/ModulePopup"
import { Mbt } from "Orders/Components/Text/Mbt/Mbt"
import { Project, ProjectSchema } from "Orders/order-data-model"
import React, { FC, FormEvent, useEffect, useRef, useState } from "react"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerProjectMode, useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { useConsumer } from "../../Client/ConsumerContext"
import {
	GetProject,
	GetProjectDefinitionResponse,
	ProjectStatus,
} from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { Loader } from "../../Loader/Loader"
import { useNavigator } from "../../Navigator/useNavigator"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { formatPhoneNumberForSweden } from "../Components/Form/constants"
import { MbtH5 } from "../Components/Text/MbtH5/MbtH5"
import { SbtH2 } from "../Components/Text/SbtH2/SbtH2"
import { SbtH6 } from "../Components/Text/SbtH6/SbtH6"
import style from "./ProjectSelectModule.module.css"

type Props = ModulePopupProps & {
	oldProjects: Project[] | null
	onSelected: (project: Project | GetProject) => void
	onNewProject: () => void
	currentSelectedProject: Project | GetProject | null
	openNewWhenNoProjects?: boolean
}

export function projectContactInformation(project: Project | GetProject, skipContactPersons: boolean): JSX.Element | null {
	let contactInformation = null

	if (
		!skipContactPersons &&
		(("contactName" in project && project?.contactName) || ("contactPhone" in project && project?.contactPhone))
	) {
		contactInformation = (
			<span>
				{project.contactName}, {formatPhoneNumberForSweden(project.contactPhone)}
			</span>
		)
	}

	const contactPersons = project?.contactPersons
	if (!skipContactPersons && isArray(contactPersons) && contactPersons.length > 0) {
		contactInformation = (
			<span>
				{contactPersons.map((item, index) => {
					return (
						<React.Fragment key={item.id}>
							{index > 0 ? "; " : ""}
							{item.name}, {formatPhoneNumberForSweden(item.phone)}
						</React.Fragment>
					)
				})}
			</span>
		)
	}

	return contactInformation
}

export const ProjectSelectModule: FC<Props> = ({
	oldProjects,
	onSelected,
	onNewProject,
	onClose,
	currentSelectedProject,
	openNewWhenNoProjects = false,
	...props
}) => {
	const auth = useAuth()
	const client = useClient()
	const consumer = useConsumer()
	const navigator = useNavigator()
	const consumerCatalogUse = useConsumerCatalog()
	const permissions = usePermissions()

	const originalProjects = useRef<(Project | GetProject)[] | null>(oldProjects)
	const [shownProjects, setShownProjects] = useState<(Project | GetProject)[] | null>()
	const [selectedProject, setSelectedProject] = useState<Project | GetProject | null>(currentSelectedProject)
	const [loadingProjects, setLoadingProjects] = useState(false)

	const [, setLocalStorageProjects] = useBrandedLocalStorage("old-projects", z.array(ProjectSchema), {
		defaultValue: [],
	})

	useEffect(() => {
		if (auth.IsLoggedIn && auth.Me) {
			let consumerId = consumer?.consumerId
			if (consumerId) {
				if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read)) {
					setLoadingProjects(false)
					setShownProjects([])
					return
				}

				setLoadingProjects(true)
				API.getWithRetries<GetProjectDefinitionResponse>(
					`/customer-portal/project-definitions-v1/${client.identifier}/${consumerId}`,
					true,
				)
					.then((res) => {
						if (!consumerId) {
							return
						}
						const projects = sortBy(
							res.projects.filter((x) => x.status === ProjectStatus.Active),
							(x) => {
								return (x.marking || x.address.street).toLocaleLowerCase()
							},
						)
						originalProjects.current = projects
						setShownProjects(projects)
						setLoadingProjects(false)

						if (openNewWhenNoProjects && projects.length === 0) {
							onNewProject()
							onClose("new_project")
						}
					})
					.catch(() => {
						setLoadingProjects(false)
					})
			}
		} else {
			setShownProjects(oldProjects)
		}
	}, [])

	const searchDebounced = debounce((event: FormEvent<HTMLInputElement>) => {
		if (event.target === null || (event.target as HTMLInputElement).value === "") {
			setShownProjects(originalProjects.current)
			return
		}

		const searchString: string = (event.target as HTMLInputElement).value.toLocaleLowerCase()

		if (originalProjects.current) {
			setShownProjects(
				originalProjects.current.filter((x) => {
					let oldContactPersonMatched = false
					if (!("id" in x)) {
						oldContactPersonMatched =
							(x.contactPhone?.toLocaleLowerCase() || "").indexOf(searchString) > -1 ||
							(x.contactName?.toLocaleLowerCase() || "").indexOf(searchString) > -1
					}
					const cityMatched =
						"city" in x
							? x.city.toLocaleLowerCase().indexOf(searchString) > -1
							: x.address.city.toLocaleLowerCase().indexOf(searchString) > -1
					const streetMatched =
						"street" in x
							? x.street.toLocaleLowerCase().indexOf(searchString) > -1
							: x.address.street.toLocaleLowerCase().indexOf(searchString) > -1
					return (
						cityMatched ||
						streetMatched ||
						(x.marking ? x.marking.toLocaleLowerCase().indexOf(searchString) > -1 : false) ||
						oldContactPersonMatched ||
						some(x.contactPersons, (y) => {
							return (
								(y.name.toLocaleLowerCase() || "").indexOf(searchString) > -1 ||
								(y.phone.toLocaleLowerCase() || "").indexOf(searchString) > -1
							)
						})
					)
				}),
			)
		} else {
			setShownProjects([])
		}
	}, 250)

	const ref = useRef(null)

	useEffect(() => {
		if (!ref.current) return // wait for the elementRef to be available
		const resizeObserver = new ResizeObserver((entries) => {
			const div: HTMLDivElement | undefined = entries[0]?.target as HTMLDivElement | undefined

			if (div) {
				const first = div.getElementsByTagName("div")[0]
				const second = div.getElementsByTagName("div")[1]

				if (first && second) {
					if (div.offsetWidth <= 150) {
						div.style.justifyContent = "center"
						first.style.display = "none"
						second.style.marginLeft = "0"
						second.style.paddingRight = "0"
					} else {
						first.style.display = "block"
						second.style.marginLeft = "auto"
						second.style.paddingRight = "0.8em"
					}
				}
			}
		})
		resizeObserver.observe(ref.current)
		return () => resizeObserver.disconnect() // clean up
	}, [])

	function noProjectsText() {
		const fullProjectMode = consumerCatalogUse.features.projectMode === ConsumerProjectMode.FullProject
		if (auth.IsLoggedInClient && !consumer?.consumerId) {
			return (
				<div className={style.clientNoProjectsTextWrapper}>
					<div style={{ fontSize: "20px", marginBottom: "10px" }}>
						Välj en kund för att kunna se deras projekt här.
					</div>
					<div style={{ marginBottom: "10px", fontWeight: 600, textDecoration: "underline" }}>
						{fullProjectMode ? "Projekt" : "Adresser"} som skapas utan en kund vald sparas enbart tillfälligt
						under order-processen
					</div>
				</div>
			)
		}

		return (
			<span style={{ color: "#666666", margin: "0 auto" }}>
				Inga {fullProjectMode ? "projekt" : "adresser"} tillgängliga
			</span>
		)
	}

	function goToSpecificProject(project: GetProject) {
		if (!auth.IsLoggedIn) {
			return
		}

		if (auth.IsLoggedInClient) {
			if (consumer?.consumerId) {
				navigator.open(`my-pages/consumers?tab=projects&consumerId=${consumer.consumerId}&projectId=${project.id}`)
			}
		} else if (auth.IsLoggedInConsumer) {
			navigator.open(`my-pages/projects?projectId=${project.id}`)
		}
	}

	function shownProjectElements(): JSX.Element {
		if (!shownProjects || shownProjects.length === 0) {
			return noProjectsText()
		}

		return (
			<>
				{shownProjects.map((project, index) => {
					let isSelected = false
					let shownCity = "city" in project ? project.city : project.address.city
					let shownStreet = "street" in project ? project.street : project.address.street
					let shownZipCode = "id" in project ? project.address.zipCode : project.zipcode
					let shownCoordinates = "id" in project ? project.address.coordinates : project.coordinates

					if (selectedProject) {
						let shownContactPersonsNames
						let shownContactPersonPhones
						if ("contactPersons" in project) {
							shownContactPersonsNames = project.contactPersons?.map((x) => x.name)
							shownContactPersonPhones = project.contactPersons?.map((x) => x.phone)
						} else {
							shownContactPersonsNames = [project.contactName]
							shownContactPersonPhones = [project.contactPhone]
						}

						let selectedCity = "city" in selectedProject ? selectedProject.city : selectedProject.address.city
						let selectedStreet =
							"street" in selectedProject ? selectedProject.street : selectedProject.address.street
						let selectedZipCode =
							"id" in selectedProject ? selectedProject.address.zipCode : selectedProject.zipcode
						let selectedCoordinates =
							"id" in selectedProject ? selectedProject.address.coordinates : selectedProject.coordinates
						let selectedContactPersonsNames
						let selectedContactPersonPhones
						if ("contactPersons" in selectedProject) {
							selectedContactPersonsNames = selectedProject.contactPersons?.map((x) => x.name)
							selectedContactPersonPhones = selectedProject.contactPersons?.map((x) => x.phone)
						} else {
							selectedContactPersonsNames = [selectedProject.contactName]
							selectedContactPersonPhones = [selectedProject.contactPhone]
						}

						isSelected =
							isEqual(shownCity, selectedCity) &&
							isEqual(shownStreet, selectedStreet) &&
							isEqual(shownZipCode, selectedZipCode) &&
							isEqual(shownCoordinates, selectedCoordinates) &&
							isEqual(shownContactPersonsNames, selectedContactPersonsNames) &&
							isEqual(shownContactPersonPhones, selectedContactPersonPhones)
					}

					let disabled = false
					if (auth.IsLoggedIn && "id" in project) {
						if (consumerCatalogUse.features.projectMode === ConsumerProjectMode.SimpleAddress) {
							disabled = !project.address.coordinates
						} else {
							disabled =
								!project.contactPersons ||
								project.contactPersons.length === 0 ||
								!project.address.coordinates ||
								(consumerCatalogUse.markingRequired && !project.marking)
						}
					} else if (!auth.IsLoggedIn && !("id" in project)) {
						let hidden
						if (consumerCatalogUse.features.projectMode === ConsumerProjectMode.SimpleAddress) {
							hidden = !project.coordinates
						} else {
							hidden =
								!project.contactName ||
								!project.coordinates ||
								(consumerCatalogUse.markingRequired && !project.marking)
						}

						if (hidden) {
							return null
						}
					}
					return (
						<FieldModuleBox
							className={style.projectRow}
							key={shownStreet + shownCity + index + 3}
							selected={isSelected}>
							<div>
								{consumerCatalogUse.features.projectMode === ConsumerProjectMode.FullProject ? (
									<>
										<MbtH5>{project.marking || shownStreet}</MbtH5>
										<Mbt>
											{shownStreet}, {shownZipCode ? shownZipCode + " " : ""}
											{shownCity}
										</Mbt>
									</>
								) : (
									<>
										<MbtH5>{shownStreet}</MbtH5>
										<Mbt>
											{shownZipCode ? shownZipCode + " " : ""}
											{shownCity}
										</Mbt>
									</>
								)}
								<Mbt>
									{projectContactInformation(
										project,
										consumerCatalogUse.features.projectMode === ConsumerProjectMode.SimpleAddress,
									)}
								</Mbt>
								{disabled ? (
									<div
										style={{
											color: "var(--invalid-color)",
											fontWeight: 400,
											marginTop: "5px",
											fontSize: "14px",
										}}>
										Kompletterande information krävs
									</div>
								) : null}
							</div>
							{disabled && "id" in project ? (
								<ModulChooserButton onClick={() => goToSpecificProject(project)} selected={!!isSelected}>
									Komplettera
								</ModulChooserButton>
							) : (
								<ModulChooserButton
									disabled={disabled}
									onClick={() => setSelectedProject(project)}
									selected={!!isSelected}>
									{isSelected ? "Vald" : "Välj"}
								</ModulChooserButton>
							)}
						</FieldModuleBox>
					)
				})}
			</>
		)
	}

	return (
		<ModulePopup onClose={onClose} {...props}>
			<div className={style.wrapper}>
				{consumerCatalogUse.features.projectMode === ConsumerProjectMode.FullProject ? (
					<SbtH2>Välj projekt</SbtH2>
				) : (
					<SbtH2>Välj adress</SbtH2>
				)}
				<SbtH6>
					För att lägga till en produkt i varukorgen behöver vi veta adressen, för att se om vi kan leverera dit
				</SbtH6>
				<div className={style.searchAndNew}>
					<div className={style.searchBox}>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a className={style.searchBtn} href="#">
							<FontAwesomeIcon icon={faSearch} />
						</a>
						<input
							type={"search"}
							className={style.searchInput}
							placeholder={"Sök"}
							onInput={(x) => searchDebounced(x)}
						/>
					</div>
					{permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Create) ? (
						<div
							ref={ref}
							className={style.newProjectButton}
							onClick={() => {
								onNewProject()
								onClose("new_project")
							}}>
							<div className={style.newProjectButtonText}>
								{consumerCatalogUse.features.projectMode === ConsumerProjectMode.FullProject
									? "Nytt projekt"
									: "Ny adress"}
							</div>
							<div className={style.newProjectButtonPlus}>+</div>
						</div>
					) : null}
				</div>
				<FieldModuleBoxWrapper className={style.projectBoxesWrapper}>
					{loadingProjects ? (
						<AbsolutCentered>
							<Loader />
						</AbsolutCentered>
					) : (
						shownProjectElements()
					)}
				</FieldModuleBoxWrapper>
				<FinalizeButton
					className={style.finalizeButton}
					disabled={!selectedProject}
					onClick={() => {
						if (!!selectedProject) {
							onSelected(selectedProject)
							onClose("old_project")
						}
					}}>
					Klar
				</FinalizeButton>
			</div>
		</ModulePopup>
	)
}
