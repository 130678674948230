import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import {
	CompanyConsumerDetails,
	GetConsumer,
	PrivateConsumerDetails,
} from "CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { exhaustive } from "exhaustive"
import { API, ServerError } from "network/API"
import { FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "Orders/Components/ModulePopup/ModulePopup"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "Orders/Components/Text/SbtInvalid/SbtRHFError"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { z } from "zod"
import { useClient } from "../../Client/ClientAndUserProvider"
import { useNavigator } from "../../Navigator/useNavigator"
import {
	invalidOrganizationNumberMessage,
	invalidPersonNumberMessage,
	organizationNumberRegex,
	personNumberRegex,
} from "../../Orders/Components/Form/constants"
import { SbtInvalid } from "../../Orders/Components/Text/SbtInvalid/SbtInvalid"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { LabelledToggle } from "../../Shared/LabelledToggle/LabelledToggle"
import style from "./ClientPortalNewConsumerModal.module.css"

const PrivateSpecificDetailsSchema = z.object({
	name: z.string().min(1),
	personNumber: z.string().min(1, "Ett personnummer krävs").regex(personNumberRegex, invalidPersonNumberMessage),
})

const BusinessSpecificDetailsSchema = z.object({
	name: z.string().min(1),
	orgNumber: z
		.string()
		.min(1, "Ett organisationsnummer krävs")
		.regex(organizationNumberRegex, invalidOrganizationNumberMessage),
})

type PrivateSpecificDetails = z.input<typeof PrivateSpecificDetailsSchema>
type BusinessSpecificDetails = z.input<typeof BusinessSpecificDetailsSchema>

export type Props = {
	onClose?: () => void
	onAdd: (consumer: GetConsumer) => void
	externalButton?: boolean
	showModal?: boolean
	setToggleToCreateIndividual?: boolean
	newConsumerButtonClass?: string
}

type CreateConsumerResponse = {
	consumer: GetConsumer
}

enum CreateConsumerErrorState {
	ConsumerAlreadyExists = "consumer_already_exists",
	RegularError = "RegularError",
}

type CreateConsumerErrorRegular = {
	type: CreateConsumerErrorState.RegularError
}

type CreateConsumerErrorConsumerAlreadyExists = {
	type: CreateConsumerErrorState.ConsumerAlreadyExists
	consumerId: string
}

type CreateConsumerError = CreateConsumerErrorRegular | CreateConsumerErrorConsumerAlreadyExists

export const ClientPortalNewConsumerModal: FC<Props> = ({
	onAdd,
	externalButton,
	showModal: extShowModal,
	onClose: extOnClose,
	setToggleToCreateIndividual,
	newConsumerButtonClass,
}) => {
	const client = useClient()
	const permissions = usePermissions()
	const navigator = useNavigator()

	const [creatingIndividualConsumer, setCreatingIndividualConsumer] = useState(false)
	const [showModal, setShowModal] = useState<boolean>(false)

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [errorSubmitting, setErrorSubmitting] = useState<CreateConsumerError | null>(null)

	useEffect(() => {
		if (!showModal) {
			setErrorSubmitting(null)
		}
	}, [showModal])

	useEffect(() => {
		setShowModal(!!extShowModal)
	}, [extShowModal])

	useEffect(() => {
		if (!setToggleToCreateIndividual) {
			setCreatingIndividualConsumer(false)
			return
		}
		setCreatingIndividualConsumer(setToggleToCreateIndividual)
	}, [setToggleToCreateIndividual])

	const toggleNewConsumerType = () => {
		setCreatingIndividualConsumer((prevValue: boolean) => {
			return !prevValue
		})
	}

	const {
		register: registerIndividual,
		handleSubmit: handleSubmitIndividual,
		reset: resetIndividual,
		formState: { errors: errorNewIndividual, isValid: isValidNewIndividual },
	} = useForm<PrivateSpecificDetails>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(PrivateSpecificDetailsSchema)(data, context, options)
		},
	})

	const {
		register: registerOrganization,
		handleSubmit: handleSubmitOrganization,
		reset: resetOrganization,
		formState: { errors: errorNewOrganization, isValid: isValidNewOrganization },
	} = useForm<BusinessSpecificDetails>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(BusinessSpecificDetailsSchema)(data, context, options)
		},
	})

	function organizationInputFields() {
		return (
			<>
				<label>
					<SbtH4>Företagsnamn*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerOrganization("name")}
						placeholder="Fyll i företagsnamn"
					/>
					<SbtRHFError error={errorNewOrganization.name} />
				</label>
				<label>
					<SbtH4>Organisationsnummer*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerOrganization("orgNumber")}
						placeholder="112233-XXXX"
					/>
					<SbtRHFError error={errorNewOrganization.orgNumber} />
				</label>
			</>
		)
	}

	function individualInputFields() {
		return (
			<>
				<label>
					<SbtH4>Namn*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerIndividual("name")}
						placeholder="Fyll i namn"
					/>
					<SbtRHFError error={errorNewIndividual.name} />
				</label>
				<label>
					<SbtH4>Personnummer*</SbtH4>
					<input
						className={style.newConsumerModalInputField}
						{...registerIndividual("personNumber")}
						placeholder="ÅÅÅÅMMDD-XXXX"
					/>
					<SbtRHFError error={errorNewIndividual.personNumber} />
				</label>
			</>
		)
	}

	function compileConsumerDetails(
		data: PrivateSpecificDetails | BusinessSpecificDetails,
	): CompanyConsumerDetails | PrivateConsumerDetails | null {
		if ("personNumber" in data) {
			return {
				type: "PrivateConsumerDetails",
				name: data.name,
				personNumber: data.personNumber,
			}
		} else if ("orgNumber" in data) {
			return {
				type: "CompanyConsumerDetails",
				name: data.name,
				orgNumber: data.orgNumber,
			}
		}

		return null
	}

	const onSubmit: SubmitHandler<PrivateSpecificDetails | BusinessSpecificDetails> = async (
		data: PrivateSpecificDetails | BusinessSpecificDetails,
	) => {
		setIsSubmitting(true)

		const consumerDetails = compileConsumerDetails(data)
		if (!consumerDetails) {
			return
		}
		type SetConsumer = {
			consumerDetails: CompanyConsumerDetails | PrivateConsumerDetails
		}
		type CreateConsumerRequest = {
			consumer: SetConsumer
		}
		const postBody: CreateConsumerRequest = {
			consumer: {
				consumerDetails: consumerDetails,
			},
		}
		return API.postWithRetries<CreateConsumerResponse>(
			`/customer-portal/consumer-manager-v1/${client.identifier}`,
			postBody,
		)
			.then((res: CreateConsumerResponse) => {
				setIsSubmitting(false)
				onAdd(res.consumer)
				setCreatingIndividualConsumer(false)
				resetIndividual()
				resetOrganization()
				setShowModal(false)
				if (extOnClose) {
					extOnClose()
				}
			})
			.catch((error: ServerError<any>) => {
				setIsSubmitting(false)
				if (
					error?.data?.message &&
					error.data.message?.startsWith(CreateConsumerErrorState.ConsumerAlreadyExists)
				) {
					setErrorSubmitting({
						type: CreateConsumerErrorState.ConsumerAlreadyExists,
						consumerId: error?.data?.message?.split("|")?.[1] || "",
					})
				} else {
					setErrorSubmitting({ type: CreateConsumerErrorState.RegularError })
				}
			})
	}

	function submitErrorSection() {
		if (!errorSubmitting) {
			return null
		}

		return exhaustive(errorSubmitting, "type", {
			[CreateConsumerErrorState.RegularError]: () => {
				return <SbtInvalid styles={{ margin: "10px 0" }}>Något gick fel, vänligen försök igen</SbtInvalid>
			},
			[CreateConsumerErrorState.ConsumerAlreadyExists]: (err) => {
				return (
					<SbtInvalid styles={{ margin: "10px 0" }}>
						Kunden du försöker skapa finns redan registrerad i vårt system. Klicka{" "}
						<span
							className={style.alreadyExistingConsumerLink}
							onClick={() => {
								navigator.open(`my-pages/consumers?tab=overview&consumerId=${err.consumerId}`)
							}}>
							här
						</span>{" "}
						för att navigera till kunden
					</SbtInvalid>
				)
			},
		})
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Create)) {
		return null
	}

	return (
		<div>
			{!externalButton ? (
				<button onClick={() => setShowModal(true)} className={cls(style.newConsumerButton, newConsumerButtonClass)}>
					<div className={style.newConsumerButtonContent}>
						<span className={style.newConsumerButtonText}>Ny kund</span>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</button>
			) : null}
			{showModal ? (
				<ModulePopup
					className={style.modal}
					show={showModal}
					onClose={() => {
						resetIndividual()
						resetOrganization()
						setCreatingIndividualConsumer(false)
						setShowModal(false)
						if (extOnClose) {
							extOnClose()
						}
					}}>
					<div className={style.wrapper}>
						<div className={style.newConsumerModalHeader}>
							<SbtH2>Skapa en ny kund</SbtH2>
						</div>
						<div style={{ marginTop: "20px", marginBottom: "20px" }}>
							<LabelledToggle
								currentValue={creatingIndividualConsumer}
								onChange={toggleNewConsumerType}
								trueLabel="Privatperson"
								falseLabel="Företag"
							/>
						</div>
						{creatingIndividualConsumer ? (
							<form
								key="form_newConsumer_individual"
								id="form_newConsumer"
								onSubmit={handleSubmitIndividual(onSubmit)}>
								{individualInputFields()}
								{submitErrorSection()}
								<FinalizeButton disabled={!isValidNewIndividual || isSubmitting} type="submit">
									Spara
									{isSubmitting ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</FinalizeButton>
							</form>
						) : (
							<form
								key="form_newConsumer_org"
								id="form_newConsumer"
								onSubmit={handleSubmitOrganization(onSubmit)}>
								{organizationInputFields()}
								{submitErrorSection()}
								<FinalizeButton disabled={!isValidNewOrganization || isSubmitting} type="submit">
									Spara
									{isSubmitting ? (
										<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
									) : null}
								</FinalizeButton>
							</form>
						)}
					</div>
				</ModulePopup>
			) : null}
		</div>
	)
}
